import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setUser, userSelector } from '../features/user'

const RequireCustomerAuth = () => {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector(userSelector)
    console.log('userBeforRefrsh', user)
    const location = useLocation()
    const logedIn = window.localStorage.getItem('isLogedIn')
    console.log('logedIn', logedIn)
    const accessToken = window.localStorage.getItem('accessToken')
    console.log('accessToken', accessToken)
    const refreshToken = window.localStorage.getItem('refreshToken')
    console.log('refreshToken', refreshToken)
    if (!user) {
        dispatch(setUser(accessToken ?? ''))
    }
    // eslint-disable-next-line no-constant-condition
    return user?.is_superuser === false || true ? <Outlet /> : <Navigate to="/" />
    // return user?.is_superuser == false ? <Outlet /> : <Outlet />
}

export default RequireCustomerAuth
