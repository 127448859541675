import {
  Button,
  Stack,
  type SxProps,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  FormGroup,
  FormLabel,
  InputAdornment,
} from '@mui/material'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router-dom'
import dashBoardServices from '../../services/cspServices'
import { useState } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'

// Validation Schema
const passwordSchema = z
  .object({
    currentPassword: z.string().min(6, 'Current password is required'),
    newPassword: z
      .string()
      .min(6, 'New password must be at least 6 characters')
      .regex(/[a-z]/, 'Must contain at least one lowercase letter')
      .regex(/[A-Z]/, 'Must contain at least one uppercase letter')
      .regex(/\d/, 'Must contain at least one number')
      .regex(
        /[@$!%*?&]/,
        'Must contain at least one special character (@$!%*?&)'
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

type PasswordFormData = z.infer<typeof passwordSchema>

interface props {
  sx?: SxProps
  setChangePassword: () => void
  setShow: React.Dispatch<React.SetStateAction<string>>
}
const PasswordChange = ({ sx, setChangePassword, setShow }: props) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmedNewPassword, setShowConfirmedNewPassword] =
    useState(false)

  const navigate = useNavigate()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<PasswordFormData> = async (data) => {
    try {
      const res = await dashBoardServices.changePassword({
        old_password: data.currentPassword,
        new_password1: data.newPassword,
        new_password2: data.confirmPassword,
      })

      console.log('res data', res.data)
      navigate('/', { replace: true })
      toast.success('Password changed successfully')
      setChangePassword()
      setShow('hidden')
    } catch (error) {
      console.log('error', error)
      toast.error('Something went wrong while changing the password!')
      if (axios.isAxiosError(error)) {
        const axiosError = error.response?.data
        const currentPasswordError: string[] = axiosError?.old_password

        if (currentPasswordError) {
          setError('currentPassword', {
            type: 'validate',
            message: currentPasswordError?.at(0),
          })
        }
      }
    }
  }
  return (
    <Stack direction={'column'} sx={sx}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ marginBottom: '10px' }}
        >
          <Typography variant="h6" fontWeight="bold">
            Change password
          </Typography>
          <IconButton onClick={setChangePassword}>
            <ArrowForwardIcon />
          </IconButton>
        </Stack>

        <Stack direction={'column'} spacing={2} mb={3}>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormLabel
                  htmlFor="currentPassword"
                  error={!!errors.currentPassword}
                >
                  Current Password
                </FormLabel>
                <TextField
                  id="currentPassword"
                  fullWidth
                  size="small"
                  defaultValue=""
                  type={showCurrentPassword ? 'text' : 'password'}
                  {...field}
                  error={!!errors.currentPassword}
                  helperText={errors.currentPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <RemoveRedEyeIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
            )}
          />

          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormLabel htmlFor="newPassword" error={!!errors.newPassword}>
                  New Password
                </FormLabel>
                <TextField
                  id="newPassword"
                  fullWidth
                  size="small"
                  type={showNewPassword ? 'text' : 'password'}
                  {...field}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <RemoveRedEyeIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormLabel htmlFor="confirmPassword">
                  Confirm Password
                </FormLabel>
                <TextField
                  id="confirmPassword"
                  fullWidth
                  size="small"
                  type={showConfirmedNewPassword ? 'text' : 'password'}
                  {...field}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmedNewPassword(
                              !showConfirmedNewPassword
                            )
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmedNewPassword ? (
                            <RemoveRedEyeIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
            )}
          />
        </Stack>
        <Button variant="contained" disabled={isSubmitting} type="submit">
          {!isSubmitting ? 'Change' : <CircularProgress size={16} />}
        </Button>
      </form>
    </Stack>
  )
}

export default PasswordChange
