import { Stack, styled } from '@mui/material'
import { useFetchCustomerImage } from './useFetchCustomerImage'

const CustomerImageWrapper = styled(Stack)({
    maxHeight: '80px',
    maxWidth: '80px',
})

const StyledCustomerImage = styled('img')({
    width: '100%',
    borderRadius: '12px',
})

const CustomerProfileImage = () => {
    const { photoData } = useFetchCustomerImage()

    return (
        <CustomerImageWrapper direction="row" justifyContent="flex-start">
            <StyledCustomerImage
                src={
                    photoData?.photo ?? require('../../assets/images/logo.png')
                }
                alt={photoData?.name ?? 'customer image'}
            />
        </CustomerImageWrapper>
    )
}

export default CustomerProfileImage
