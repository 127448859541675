import { CircularProgress, Stack } from '@mui/material'
import React, { useState } from 'react'
import CreateEngineer from '../../assets/images/emptyEngneer.svg'
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../components/common/CommonButton/CommonButton'
import EngineerCreateModal from '../../components/Modals/CreateUpdateEngineerModal/CreateUpdateEngineerModal'
import CommonTypography from '../../components/common/CommonTypography/CommonTypography'
import dashBoardServices from '../../services/cspServices'
import useFetch from '../../hooks/useFetch'
import ListingEngineers from '../../contents/CustomersContent/ListingEngineers/ListingEngineers'
import DeleteEngineerModal from '../../components/Modals/DeleteEngineerModal/DeleteEngineerModal'
import toast from 'react-hot-toast'
import axios from 'axios'

type DeleteRequestError = {
  error: string
  protected_elements: {
    ticket_id: string
    subject: string
    status: string
  }[]
}

const EmptyEngineersState = () => {
  const [selectedID, setSelectedID] = useState<any>(undefined)
  const [selectedEng, setSelectedEng] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { data: engineers, isPending: isFetching } = useFetch(
    dashBoardServices.getEngineers,
    refetch
  )
  console.log('engineers', engineers)
  const createNew = () => {
    setOpen(true)
  }

  const confirmEngineerDeletion = async () => {
    try {
      setLoading(true)
      await dashBoardServices.deleteEngineer(selectedID)
      toast.success('Engineer deleted successfully')
      setRefetch(!refetch)
      setLoading(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const deleteRequestError: DeleteRequestError = error.response?.data

        const errorStatus = error.response?.status

        if (errorStatus === 400) {
          deleteRequestError?.protected_elements?.forEach((element) => {
            toast.error(
              `Ticket ID: ${element.ticket_id}, Subject: ${element.subject}, Status: ${element.status}`
            )
          })
          toast.error(
            'Engineer has associated tickets! reassign to proceed with deletion.',
            {
              duration: 5000,
            }
          )
        } else {
          toast.error('An error occurred while deleting the engineer')
        }
      }
    } finally {
      setLoading(false)
      setOpenDeleteModal(false)
      setSelectedID(undefined)
      setSelectedEng(undefined)
    }
  }
  return (
    <Stack direction={'column'}>
      <Stack direction={'row'} spacing={0.5} sx={{ paddingLeft: '32px' }}>
        <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
          IT-RANKS Engineers
        </CommonTypography>
      </Stack>
      {!isFetching && engineers.length === 0 && (
        <Stack
          sx={{
            minHeight: '95vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          direction={'row'}
        >
          <CommonEmptyState
            src={CreateEngineer}
            alt="site empty state"
            width={81}
            height={180}
            sx={{ marginTop: '88px' }}
          >
            <CommonButton
              variant={'contained'}
              sx={{ width: 200, height: 40 }}
              onClick={createNew}
            >
              New Engineer
            </CommonButton>
          </CommonEmptyState>
        </Stack>
      )}
      {isFetching && (
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ minHeight: '80vh' }}
        >
          <CircularProgress color="inherit" size={50} />
        </Stack>
      )}
      {!isFetching && engineers.length > 0 && (
        <ListingEngineers
          engineers={engineers}
          seeDetails={(e, index) => {
            console.log('asasasa', e, index)
            setSelectedID(e)
            setSelectedEng(engineers[index])
            setOpen(true)
          }}
          createNew={createNew}
        />
      )}
      <EngineerCreateModal
        id={selectedID}
        Engineer={selectedEng}
        open={open}
        handleClose={() => {
          setSelectedID(undefined)
          setSelectedEng(undefined)
          setOpen(false)
          setRefetch(!refetch)
        }}
        handleDelete={() => {
          setOpen(false)
          setOpenDeleteModal(true)
        }}
      />
      <DeleteEngineerModal
        handleClose={() => {
          setOpenDeleteModal(false)
          setSelectedID(undefined)
          setSelectedEng(undefined)
        }}
        open={openDeleteModal}
        handleMainAction={confirmEngineerDeletion}
        isPending={loading}
      />
    </Stack>
  )
}

export default EmptyEngineersState
