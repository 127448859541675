import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../../services/cspServices'
import { IncidentFile } from './types'
import { AxiosResponse } from 'axios'
import baseURL from '../../../../services/baseUrl'

const useIncidentFiles = () => {
  const { id: incidentId } = useParams()

  const [incidentFiles, setIncidentFiles] = useState<IncidentFile[]>([])

  const [loading, setLoading] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState<IncidentFile[]>([])

  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false)

  const toggleFileSelection = (file: IncidentFile) => {
    setSelectedFiles((prev) =>
      prev.some((f) => f.id === file.id)
        ? prev.filter((f) => f.id !== file.id)
        : [...prev, file]
    )
  }

  const downloadFile = async (file: IncidentFile) => {
    const filePath = file.file
    const fileName = file.file_name
    try {
      setLoadingDownloadFile(true)
      const fileURL = baseURL().globalUrl + filePath
      const res = await apiClient.get(fileURL, { responseType: 'blob' })
      console.log('res ', res)
      setLoadingDownloadFile(false)
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
      toast.error('Error downloading file')
      setLoadingDownloadFile(false)
    }
  }

  const downloadSelectedFiles = () => {
    selectedFiles.forEach((file) => downloadFile(file))
    setSelectedFiles([])
  }

  useEffect(() => {
    const fetchIncidentFiles = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<IncidentFile[]> = await apiClient.get(
          `/v2/repo/request/${incidentId}/files/`
        )
        console.log('Incident files:', res.data)
        setIncidentFiles(res.data)
      } catch (error) {
        console.error('Error fetching incident files:', error)
        toast.error('Error fetching incident files')
      } finally {
        setLoading(false)
      }
    }
    fetchIncidentFiles()
  }, [incidentId])

  return {
    incidentFiles,
    toggleFileSelection,
    loading,
    selectedFiles,
    loadingDownloadFile,
    downloadSelectedFiles,
  }
}

export default useIncidentFiles
