import { Stack, Avatar, Typography } from '@mui/material'

type Props = {
  name: string
  email: string
  props: React.HTMLAttributes<HTMLLIElement>
}

const UserProfileItem = ({ name, email, props }: Props) => {
  return (
    <Stack
      component="li"
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'flex-center',
        padding: 2,
      }}
    >
      <Avatar
        sx={{
          width: 36,
          height: 36,
        }}
      >
        {name.at(0)}
      </Avatar>
      <Stack>
        <Typography variant="body1" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {email}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default UserProfileItem
