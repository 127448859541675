import { useState, useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectUserReducer } from '../../../features/user'
import dashBoardServices from '../../../services/cspServices'
import { CustomerCategories, Priority, Skill } from './types'

export const useFetchCustomerData = () => {
    const [categories, setCategories] = useState<CustomerCategories[]>([])
    const [priorities, setPriorities] = useState<Priority[]>([])
    const [subCategories, setSubCategories] = useState<Skill[]>([])
    const { user } = useAppSelector(selectUserReducer)

    useEffect(() => {
        if (user.customer_id) {
            const fetchCategories = async () => {
                try {
                    const response =
                        await dashBoardServices.getCustomerCategories(
                            user.customer_id
                        )
                    setCategories(response.data)
                } catch (error) {
                    console.error('Error fetching categories:', error)
                }
            }

            const fetchPriorityData = async () => {
                try {
                    const response =
                        await dashBoardServices.getCustomerPriorities(
                            user.customer_id
                        )

                    setPriorities(response.data[0].priorities)
                } catch (error) {
                    console.error('Error fetching priorities data:', error)
                }
            }

            fetchCategories()
            fetchPriorityData()
        }
    }, [user.customer_id])

    return { categories, priorities, subCategories, setSubCategories }
}
