import {
    Button,
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
} from '@mui/material'
import { Container } from '@mui/system'
import React, { useEffect, useState, useCallback } from 'react'
import {
    BubbleChart,
    DateRangePicker,
    MonthPicker,
    Priority,
    PriorityAllocation,
    ProductSelector,
    ResolutionDelay,
    ResolutionTime,
    ResponseDelay,
    ResponseTime,
    StatusAllocation,
} from '../../DashboardComponents'
import './app.css'
import dashBoardServices from '../../services/cspServices'
import FetchingData from '../../components/Loaders/FtechingData/FetchingData'
import dayjs from 'dayjs'
import { AreaUnderLine } from '../../DashboardComponents/AreaUnderLine/AreaUnderLine'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import useFetch from '../../hooks/useFetch'
import { LoadingButton } from '@mui/lab'
export const DashboardPage = () => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(null)
    const [searchDate, setSearchDate] = useState<string>('')
    const [searchProduct, setSearchProduct] = useState<string>('')
    const [searchPriority, setSearchPriority] = useState<string>('')
    const [fromDate, setFromDate] = useState<any>(undefined)
    const [toDate, setToDate] = useState<any>(undefined)
    const { user } = useAppSelector(userSelector)
    const [selectedPriority, setSelectedPriority] = useState<number[]>([])
    const [selectedProducts, setSelectedProducts] = useState<any>([])
    const [filterChanged, setFilterChanged] = useState<boolean>(true)
    const [selectedRadio, setSelectedRadio] = useState('all') // 'all' is the initial value

    const {
        data: productsData,
        isPending,
        error: errorProductsData,
    } = useFetch(
        dashBoardServices.getCustomerCategories,
        user.customer_id ?? undefined
    )

    const {
        data: priorityData,
        isPending: isPendingPriority,
        error: errorPriority,
    } = useFetch(
        dashBoardServices.getCustomerPriorities,
        user.customer_id ?? undefined
    )

    const onProductSelect = (productIds: number[]) => {
        console.log('productIds', productIds)
        if (productIds.length > 0) {
            const newSearchStr = productIds
                .map((productId) => `product=${productId}`)
                .join('&')
            setSearchProduct(newSearchStr)
            console.log('Query string:', newSearchStr)
        } else {
            setSearchProduct('')
        }
        setFilterChanged(false)
    }
    const onPrioritySelect = (selectedPriorities: number[]) => {
        if (selectedPriorities.length > 0) {
            const newSearchStr = selectedPriorities
                .map((priority) => `priority=${priority}`)
                .join('&')
            setSearchPriority(newSearchStr)
        } else {
            setSearchPriority('')
        }
        setFilterChanged(false)
    }

    const onDateSelect = (date: any) => {
        console.log('date', date)
        let strSearch: string = ''

        if (date.fromDate !== '' && date.fromDate) {
            setFilterChanged(false)
            strSearch +=
                'starting_date=' + dayjs(date.fromDate).format('DD/MM/YYYY')
        }

        if (date.toDate !== '' && date.toDate) {
            let endDate = dayjs(date.toDate)

            // Check if the selected end date is today
            if (
                endDate.isSame(dayjs(), 'day') ||
                endDate.isSame(dayjs(date.fromDate), 'day')
            ) {
                // If it is today, set it to tomorrow
                endDate = endDate.add(1, 'day')
            }

            if (strSearch !== '') {
                strSearch += '&'
            }
            strSearch += 'ending_date=' + endDate.format('DD/MM/YYYY')
        }
        if (strSearch !== '') {
            setSearchDate(strSearch)
        } else {
            setSearchDate('')
        }
        console.log('Query string:', strSearch)
    }
    const handleDateSelect = () => {
        if (onDateSelect) {
            onDateSelect({ fromDate, toDate })
        }
    }
    const resetFilters = () => {
        setSearchDate('')
        setSearchProduct('')
        setSearchPriority('')
        setFromDate('')
        setToDate('')
        setSelectedPriority([])
        setSelectedProducts([])
        setFilterChanged(true)
        setSelectedRadio('all')
    }
    useEffect(() => {
        handleApply()
    }, [])
    const handleApply = () => {
        setLoading(true)
        let str: string = ''
        if (
            searchDate !== '' ||
            searchPriority !== '' ||
            searchProduct !== '' ||
            selectedRadio == 'my'
        ) {
            console.log('in if state', str)
            str = '?'
            if (searchDate !== '') {
                str += searchDate
            }

            if (searchPriority !== '') {
                if (str !== '?') {
                    str += '&'
                }
                str += searchPriority
            }

            if (searchProduct !== '') {
                if (str !== '?') {
                    str += '&'
                }
                str += searchProduct
            }

            if (selectedRadio == 'my') {
                if (str !== '?') {
                    str += '&'
                }
                str += 'my_requests=true'
            }
        }
        dashBoardServices
            .getDashbaord(str)
            .then((res) => {
                setData(res.data)
            })
            .catch((e) => {
                setError(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        handleDateSelect()
    }, [handleDateSelect])

    //  if(loading) return <FetchingData />
    return (
        <>
            {!error && (
                <Stack
                    direction={'row'}
                    padding={2}
                    columnGap={2}
                    justifyContent={'space-evenly'}
                    sx={{
                        marginTop: '4%',
                        borderRadius: '16px',
                        backgroundColor: '#FFF',
                        marginInline: '2%',
                    }}
                >
                    <Stack
                        direction={'column'}
                        rowGap={2}
                        justifyContent={'center'}
                        sx={{
                            // border: "5px solid #221f1f",
                            borderRight: '1px solid #E9E9E9',
                            paddingInline: '1.5%',
                        }}
                    >
                        {user.is_admin && (
                            <RadioGroup
                                row
                                aria-label="filter-by"
                                name="filter-by"
                                value={selectedRadio}
                                onChange={(event) => {
                                    setSelectedRadio(event.target.value)
                                    setFilterChanged(false)
                                }}
                            >
                                <FormControlLabel
                                    value="all"
                                    control={<Radio />}
                                    label="All Requests"
                                />
                                <FormControlLabel
                                    value="my"
                                    control={<Radio />}
                                    label="My Requests"
                                />
                            </RadioGroup>
                        )}

                        <DateRangePicker
                            fromDate={fromDate}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                            toDate={toDate}
                        />
                        {/* <MonthPicker onMonthSelect={null} /> */}
                        <ProductSelector
                            onProductSelect={onProductSelect}
                            products={productsData}
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                        />
                        <Priority
                            priorityData={priorityData}
                            onPrioritySelect={onPrioritySelect}
                            selectedPriority={selectedPriority}
                            setSelectedPriority={setSelectedPriority}
                        />
                        <Stack
                            sx={{ marginTop: '48px' }}
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                fullWidth
                                sx={{
                                    // width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                disabled={filterChanged} // Disable the button if no filter has changed
                                onClick={() => {
                                    resetFilters()
                                }}
                            >
                                {'reset'}
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                fullWidth
                                variant={'contained'}
                                sx={{
                                    // width: '140px',
                                    height: '40px',
                                    boxShadow: 'none',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    borderRadius: '4px',
                                }}
                                onClick={handleApply}
                                loading={false}
                            >
                                Apply
                            </LoadingButton>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'column'}
                        rowGap={2}
                        justifyContent={'center'}
                        flexGrow={loading ? 0.5 : 0}
                    >
                        {loading && !error ? (
                            <CircularProgress color="inherit" size={50} />
                        ) : (
                            <>
                                {/* <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                <ResolutionTime
                                    violatedResolutionTime={
                                        data?.violated_resolution_time
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                <ResponseTime
                                    violatedResponseTime={
                                        data?.violated_response_time
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                <ResolutionDelay
                                    hours={data?.resolution_delay_time.hours}
                                    minutes={
                                        data?.resolution_delay_time.minutes
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                                <ResponseDelay
                                    hours={data?.response_delay_time.hours}
                                    minutes={data?.response_delay_time.minutes}
                                />
                            </Grid>
                        </Grid> */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12} xl={6}>
                                        <PriorityAllocation
                                            priorityData={
                                                data?.priorty_allocation
                                            }
                                            prioritiesCount={
                                                data?.priorities_count
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={6}>
                                        <StatusAllocation
                                            data={data?.statues_allocation}
                                            counts={data?.total_requests_count}
                                        />
                                    </Grid>
                                </Grid>
                                <Stack direction={'row'}>
                                    <AreaUnderLine
                                        rawData={data?.total_requests_count}
                                    />
                                    {/* <BubbleChart   /> */}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
            )}
        </>
    )
}
