import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { toast } from 'react-hot-toast'
import { Task } from './types'
import { apiClient } from '../../services/cspServices'

const useTaskDetails = () => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()

  const [task, setTask] = useState<Task>({
    id: '',
    title: '',
    engineer: {
      id: 0,
      user: {
        full_name: '',
        email: '',
        jop_title: '',
        phone: '',
        is_active: false,
      },
    },
    priority: '',
    impact: '',
    description: '',
    created_by: {
      id: 0,
      user: {
        full_name: '',
        email: '',
        jop_title: '',
        phone: '',
        is_active: false,
      },
    },
    created_at: '',
    request: '',
    attachments: [],
    customer: {
      email: '',
      full_name: '',
      jop_title: '',
      phone: '',
      is_active: false,
    },
    status: '',
    total_effort_hours: ''
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<Task> = await apiClient.get(
          `/v2/repo/request/${incidentId}/task/${taskId}/`
        )
        setTask(res.data)
      } catch (error) {
        console.error('Error fetching task details:', error)
        toast.error('Error fetching task details')
      } finally {
        setLoading(false)
      }
    }
    fetchTaskDetails()
  }, [incidentId, taskId])

  return {
    task,
    loading,
  }
}

export default useTaskDetails
