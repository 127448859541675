import React, { useEffect } from 'react'
import { TextField, MenuItem, InputLabel, FormGroup, Grid } from '@mui/material'
import type { Control, FieldErrors, UseFormSetValue } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import type { FormData } from './FormData'
import { CustomerCategories, Priority, Skill } from './types'
import { IncidentImpact } from '../../../interfaces/IncidentImpact'

type RequestFormProps = {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  setValue: UseFormSetValue<FormData>
  selectedCategoryId: string
  categories: CustomerCategories[]
  priorities: Priority[]
  subCategories: Skill[]
  setSubCategories: React.Dispatch<React.SetStateAction<Skill[]>>
}

export const incidentImpactItems: IncidentImpact[] = [
  {
    key: 'I',
    impactLabel: 'Individually',
  },
  {
    key: 'E',
    impactLabel: 'Entire Organization',
  },
  {
    key: 'D',
    impactLabel: 'Department',
  },
]

const RequestForm: React.FC<RequestFormProps> = ({
  control,
  errors,
  setValue,
  selectedCategoryId,
  categories,
  priorities,
  subCategories,
  setSubCategories,
}) => {
  useEffect(() => {
    if (selectedCategoryId) {
      const subCategories = categories.find((c) => c.id === +selectedCategoryId)
        ?.skills as Skill[]

      setSubCategories(subCategories)
      setValue('subCategory', '')
    } else {
      setSubCategories([])
      setValue('subCategory', '')
    }
  }, [selectedCategoryId])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="customerName"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="customer-name"
                error={!!errors.customerName}
                required
              >
                Name
              </InputLabel>
              <TextField
                id="customer-name"
                fullWidth
                size="small"
                {...field}
                error={!!errors.customerName}
                helperText={
                  !!errors.customerName && errors.customerName.message
                }
                disabled
              />
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="customer-email"
                error={!!errors.email}
                required
              >
                Email
              </InputLabel>
              <TextField
                id="customer-email"
                fullWidth
                size="small"
                {...field}
                error={!!errors.email}
                helperText={!!errors.email && errors.email.message}
                disabled
              />
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel htmlFor="customer-phone" error={!!errors.phone}>
                Phone Number
              </InputLabel>
              <TextField
                id="customer-phone"
                fullWidth
                size="small"
                {...field}
                error={!!errors.phone}
                helperText={!!errors.phone && errors.phone.message}
                disabled
              />
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-priority"
                error={!!errors.priority}
                required
              >
                Priority
              </InputLabel>
              <TextField
                id="incident-priority"
                fullWidth
                size="small"
                {...field}
                select
                error={!!errors.priority}
                helperText={!!errors.priority && errors.priority.message}
              >
                {priorities.map((item, i: number) => (
                  <MenuItem key={`priority${i}`} value={item.id.toString()}>
                    {item.priority_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="impact"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-impact"
                error={!!errors.impact}
                required
              >
                Impact
              </InputLabel>
              <TextField
                id="incident-impact"
                fullWidth
                size="small"
                {...field}
                select
                error={!!errors.impact}
                helperText={!!errors.impact && errors.impact.message}
              >
                {incidentImpactItems.map((impact) => (
                  <MenuItem key={impact.key} value={impact.key}>
                    {impact.impactLabel}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-category"
                error={!!errors.category}
                required
              >
                Category
              </InputLabel>
              <TextField
                id="incident-category"
                fullWidth
                size="small"
                {...field}
                select
                error={!!errors.category}
                helperText={!!errors.category && errors.category.message}
              >
                {categories.map((item) => (
                  <MenuItem
                    key={`product-${item.id}`}
                    value={item.id.toString()}
                  >
                    {item.product.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="subCategory"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-subCategory"
                error={!!errors.subCategory}
                required
              >
                Sub Category
              </InputLabel>
              <TextField
                id="incident-subCategory"
                fullWidth
                size="small"
                {...field}
                select
                error={!!errors.subCategory}
                helperText={!!errors.subCategory && errors.subCategory.message}
                disabled={!selectedCategoryId}
              >
                {subCategories.map((item) => (
                  <MenuItem key={item.skill.id} value={item.id.toString()}>
                    {item.skill.skill}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-title"
                error={!!errors.title}
                required
              >
                Title
              </InputLabel>
              <TextField
                id="incident-title"
                fullWidth
                size="small"
                {...field}
                error={!!errors.title}
                helperText={!!errors.title && errors.title.message}
              />
            </FormGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel
                htmlFor="incident-description"
                error={!!errors.description}
                required
              >
                Description
              </InputLabel>
              <TextField
                id="incident-description"
                fullWidth
                size="small"
                {...field}
                error={!!errors.description}
                helperText={!!errors.description && errors.description.message}
                multiline
                minRows={4}
              />
            </FormGroup>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default RequestForm
