import React, { useState, useEffect } from 'react'
import dashBoardServices from '../../services/cspServices'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'

export type CustomerPhotoData = {
    id: number
    name: string
    photo: string
}

export const useFetchCustomerImage = () => {
    const { user } = useAppSelector(userSelector)
    const { customer_id: customerId } = user

    const [PhotoIsPending, setPhotoIsPending] = useState(true)
    const [photoData, setPhotoData] = useState<CustomerPhotoData | null>(null)

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                const response = await dashBoardServices.getCustomerPhoto(
                    customerId
                )
                setPhotoData(response.data)
                setPhotoIsPending(false)
            } catch (error) {
                console.log('failed to load customer image', error)
                setPhotoIsPending(false)
            }
        }
        fetchPhoto()
    }, [customerId])

    return {
        PhotoIsPending,
        photoData,
    }
}
