import { useState } from 'react'
import { AttachmentFile } from './types'
import dashBoardServices from '../../../services/cspServices'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'

type AttachmentResourceName = 'INCIDENT' | 'TASK' | 'NOTE'

type UploadedFile = {
  id: number
  file: string //  the URL of the file
  file_name: string
}

export const useFileAttachments = (
  resourceName: AttachmentResourceName = 'INCIDENT'
) => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()
  const [fileLoading, setFileLoading] = useState(false)
  const [attachmentFiles, setAttachmentFiles] = useState<AttachmentFile[]>([])

  const deleteFileAttachment = async (attachmentId: number) => {
    try {
      let res: AxiosResponse | null = null
      switch (resourceName) {
        case 'INCIDENT':
          res = await dashBoardServices.deleteIncidentAttachment(attachmentId)
          break

        case 'TASK':
          res = await dashBoardServices.deleteTaskAttachment(
            attachmentId,
            incidentId as string
          )
          break

        case 'NOTE':
          if (taskId) {
            res = await dashBoardServices.deleteTaskNoteAttachment(
              attachmentId,
              incidentId as string,
              taskId
            )
          } else {
            res = await dashBoardServices.deleteNoteAttachment(
              attachmentId,
              incidentId as string
            )
          }
          break

        default:
          throw new Error(`Unsupported resource name: ${resourceName}`)
      }

      if (res?.status === 204) {
        setAttachmentFiles(
          attachmentFiles.filter((file) => file.id !== attachmentId)
        )

        toast.success('Attachment deleted successfully')
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      toast.error('Something went wrong while deleting the file')
    }
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFileLoading(true)
    const files = event.target.files
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const formData = new FormData()
        formData.append('file', file)
        try {
          let res: AxiosResponse<UploadedFile> | null = null

          switch (resourceName) {
            case 'INCIDENT':
              res = await dashBoardServices.uploadIncidentAttachments(formData)
              break
            case 'TASK':
              res = await dashBoardServices.uploadTaskAttachments(
                formData,
                incidentId as string
              )
              break
            case 'NOTE':
              if (taskId) {
                res = await dashBoardServices.uploadTaskNoteAttachments(
                  formData,
                  incidentId as string,
                  taskId
                )
              } else {
                res = await dashBoardServices.uploadNoteAttachments(
                  formData,
                  incidentId as string
                )
              }
              break

            default:
              throw new Error(`Unsupported resource name: ${resourceName}`)
          }
          const resData = res?.data
          const fileObj = { id: resData?.id, name: resData?.file_name }
          setAttachmentFiles((prev) => [...prev, fileObj])
          toast.success('Attachment uploaded successfully')
        } catch (error) {
          console.error('Error uploading file:', error)
          if (axios.isAxiosError(error)) {
            const resError = error.response
            resError?.status === 403 &&
              toast.error(
                resError?.data?.detail ??
                  'You are not authorized to upload files'
              )
          } else {
            toast.error('Error uploading file')
          }
        }
      }
    }
    setFileLoading(false)
  }

  return {
    fileLoading,
    attachmentFiles,
    setAttachmentFiles,
    deleteFileAttachment,
    handleFileUpload,
  }
}
