import { useEffect, useState } from 'react'
import {
  Typography,
  Stack,
  styled,
  Menu,
  MenuItem,
  Button,
} from '@mui/material'

import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'

import { titleCase } from '../../helper/utils'
import { ArrowDropDown } from '@mui/icons-material'
import { apiClient } from '../../services/cspServices'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const IncidentStatusWrapper = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F6FFFB',
  padding: '8px',
  borderRadius: '4px',
})

type Props = {
  currentStatus: string
  resourceName?: 'INCIDENT' | 'TASK'
}

type StatusOption = {
  key: string
  label: string
}

const IncidentStatusCard = ({
  currentStatus,
  resourceName = 'INCIDENT',
}: Props) => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()

  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' | 'ADMIN' = user?.user_type

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [incidentStatus, setIncidentStatus] = useState(currentStatus)
  const [statusOptions, setStatusOptions] = useState<StatusOption[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchStatusOptions = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<StatusOption[]> = await apiClient.get(
          `/v2/query/${
            resourceName === 'INCIDENT' ? 'incident' : 'task'
          }/status/`
        )
        console.log('Status options:', res.data)
        setStatusOptions(res.data)
      } catch (error) {
        console.error('Error fetching status options:', error)
        toast.error('Error fetching status options')
      } finally {
        setLoading(false)
      }
    }
    fetchStatusOptions()
  }, [resourceName])

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeStatus = async (newStatus: string) => {
    setLoading(true)
    try {
      const url =
        resourceName === 'INCIDENT'
          ? `/v2/service/request/${incidentId}/update/`
          : `/v2/service/request/${incidentId}/task/${taskId}/update/`
      const res = await apiClient.patch(url, { status: newStatus })
      console.log(' status updated:', res.data)
      setIncidentStatus(newStatus)
      toast.success(`${titleCase(resourceName)} status updated successfully`)
      handleClose()
    } catch (error) {
      console.error('Error updating status:', error)
      toast.error(`Error updating ${titleCase(resourceName)} status`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <IncidentStatusWrapper>
      <Typography
        sx={{
          flex: 1,
        }}
        color="common.black"
      >
        Status
      </Typography>

      {userType === 'CUSTOMER' && (
        <Typography
          sx={{
            backgroundColor: '#47A5A61A',
            color: '#47A5A6',
            padding: '4px 12px',
            borderRadius: '3px',
          }}
        >
          {titleCase(incidentStatus)}
        </Typography>
      )}

      {(userType === 'ENGINEER' || userType === 'ADMIN') && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            disabled={loading}
          >
            {titleCase(incidentStatus)}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {statusOptions.map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => handleChangeStatus(option.key)}
                selected={option.key === incidentStatus}
                disabled={option.key === incidentStatus}
              >
                {titleCase(option.label)}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </IncidentStatusWrapper>
  )
}

export default IncidentStatusCard
