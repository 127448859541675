import { Stack, Box, styled } from '@mui/material'
import SummaryCard from '../../components/IncidentDetails/IncidentSummaryCard'
import IncidentBasicInfoCard from '../../components/IncidentDetails/IncidentBasicInfoCard'
import IncidentTabsSection from '../../components/IncidentDetails/IncidentTabs/IncidentTabsSection'
import useIncidentDetails from '../../components/IncidentDetails/useIncidentDetails'
import LoadingSpinner from '../../components/shared/LoadingSpinner'

const RootLayout = styled('div')({
  marginTop: '60px',
  width: '100%',
  minHeight: '100vh',
  display: 'grid',
  gridTemplateColumns: '5fr 2fr',
  gap: '24px',
  padding: '32px',

  '@media (max-width: 1024px)': {
    gridTemplateColumns: '1fr',
    padding: '16px',
  },
})

const SummaryBox = styled(Box)({
  position: 'sticky',
  top: '8px', // Adjust based on navbar height
  alignSelf: 'start',

  '@media (max-width: 1024px)': {
    position: 'static', // Remove stickiness on smaller screens
  },
})

const IncidentDetailsPage = () => {
  const { incident, loading } = useIncidentDetails()

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <RootLayout>
      <Stack spacing={3}>
        <IncidentBasicInfoCard
          incidentId={incident?.id}
          incidentTitle={incident?.subject}
          incidentDescription={incident?.description}
          customerName={incident?.customer_name}
          customerEmail={incident?.customer_email}
          customerPhone={incident?.customer_phone}
          customerLocation={incident?.category?.customer_product?.site?.name}
          shouldAddNote={incident?.add_note}
        />
        <IncidentTabsSection customerId={incident?.customer_organization_id} />
      </Stack>

      <SummaryBox>
        <SummaryCard
          customerId={incident?.customer_organization_id}
          incidentStatus={incident?.status}
          incidentEffortHours={incident?.total_effort_hours}
          incidentPriority={incident?.priority}
          assignedTo={incident?.assigned_to}
          openedAt={incident?.opened_at}
          incidentCategory={incident?.category?.customer_product?.product?.name}
          incidentImpact={incident?.impact}
          lastUpdated={incident?.updated_at}
          responseTime={incident?.response_time}
          resolvedTime={incident?.resolved_time}
          escalationStatus={incident?.escalation_status}
          bugRelated={incident?.bug_related}
          SLAResponseTime={incident?.sla_response_time}
        />
      </SummaryBox>
    </RootLayout>
  )
}

export default IncidentDetailsPage
