import {
  Box,
  Tabs,
  Tab,
  styled,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material'
import { useTabs } from '../../../hooks/useTabs'
import { TaskHistory } from './TaskHistory'
import { TaskFiles } from './TaskFiles'
import { FileDownload } from '@mui/icons-material'
import useTasksFiles from './TaskFiles/useTaskFiles'

const TabsWrapper = styled(Tabs)({
  minHeight: '34px',
  backgroundColor: '#D9E7E9',
  padding: '5px',
  borderRadius: '10px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTab-root': {
    padding: '0 60px',
    minHeight: '34px',
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: '#666',
  backgroundColor: 'transparent',

  '&.Mui-selected': {
    borderRadius: '10px',
    color: '#fff',
    backgroundColor: '#47A5A6',
  },
}))

const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.teal.main,
  borderColor: theme.palette.teal.main,
  padding: '6px 24px',
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    backgroundColor: theme.palette.teal.main,
    borderColor: theme.palette.teal.main,
    color: '#fff',
  },
}))

const TABS = [
  {
    value: 'history',
    label: 'History',
  },
  {
    value: 'files',
    label: 'Files',
  },
]

const TaskTabsSection = () => {
  const tabs = useTabs('history')

  const {
    taskFiles,
    toggleFileSelection,
    loading,
    selectedFiles,
    loadingDownloadFile,
    downloadSelectedFiles,
  } = useTasksFiles()

  return (
    <Box width="100%" component="section">
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
        justifyContent="space-between"
        alignItems="start"
        mb={3}
      >
        <TabsWrapper
          variant="fullWidth"
          value={tabs.value}
          onChange={tabs.onChange}
        >
          {TABS.map((tab) => (
            <StyledTab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>

        {tabs.value === 'files' && (
          <OutlinedButton
            variant="outlined"
            disabled={loadingDownloadFile || selectedFiles.length === 0}
            startIcon={
              loading ? <CircularProgress size={20} /> : <FileDownload />
            }
            onClick={() => {
              downloadSelectedFiles()
            }}
          >
            Download All
          </OutlinedButton>
        )}
      </Stack>

      {tabs.value === 'history' && <TaskHistory />}
      {tabs.value === 'files' && (
        <TaskFiles
          taskFiles={taskFiles}
          selectedFiles={selectedFiles}
          loading={loading}
          toggleFileSelection={toggleFileSelection}
        />
      )}
    </Box>
  )
}

export default TaskTabsSection
