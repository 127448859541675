import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TableRowsLoader from '../../../common/TableRowsLoader'
import useTaskHistory from './useTaskHistory'
import ExpandableText from '../../../shared/ExpandableText'

const TaskHistory = () => {
  const { taskHistory, loading } = useTaskHistory()

  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actioned By</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Effort</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRowsLoader numberOfRows={5} numberOfCells={5} />
              ) : (
                taskHistory.map((task, index) => (
                  <TableRow key={index}>
                    <TableCell>{task.actioned_by.full_name}</TableCell>
                    <TableCell>
                      <ExpandableText text={task.description} />
                    </TableCell>
                    <TableCell>{task.created_at}</TableCell>
                    <TableCell>{task.effort}</TableCell>
                    <TableCell>
                      {task.email_sent ? (
                        <CheckIcon
                          sx={{
                            color: '#12A3B0',
                          }}
                        />
                      ) : (
                        <CloseIcon sx={{ color: '#FF5252' }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default TaskHistory
