import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import { TaskHistory } from './types'
import { apiClient } from '../../../../services/cspServices'

const useTaskHistory = () => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()

  const [taskHistory, setTaskHistory] = useState<TaskHistory[]>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTaskHistory = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<TaskHistory[]> = await apiClient.get(
          `/v2/repo/request/${incidentId}/task/${taskId}/history`
        )
        console.log('task history:', res.data)
        setTaskHistory(res.data)
      } catch (error) {
        console.error('Error fetching task history:', error)
        toast.error('Error fetching task history')
      } finally {
        setLoading(false)
      }
    }
    fetchTaskHistory()
  }, [incidentId, taskId])

  return {
    taskHistory,
    loading,
  }
}

export default useTaskHistory
