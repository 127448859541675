import { Stack, Typography, Button, styled } from '@mui/material'
import { ReactComponent as NoRequestsIcon } from '../../assets/images/request.svg'

const NewRequestButton = styled(Button)({
    borderRadius: '4px',
    marginTop: '24px',
    backgroundColor: '#494454',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: '24px',
    textTransform: 'none',
    minWidth: '195px',
})

type Props = {
    userType: string
    handleOpenNewRequest: () => void
}

const RequestsEmptyState = ({ userType, handleOpenNewRequest }: Props) => (
    <Stack direction={'column'}>
        <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ minHeight: '93vh' }}
        >
            <NoRequestsIcon />
            <Typography fontWeight="bold" variant="subtitle1">
                No requests until now
            </Typography>

            {userType === 'CUSTOMER' && (
                <NewRequestButton disableRipple onClick={handleOpenNewRequest}>
                    New Request
                </NewRequestButton>
            )}
        </Stack>
    </Stack>
)

export default RequestsEmptyState
