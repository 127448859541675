import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import {
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    Button,
    Stack,
    useMediaQuery,
    Pagination,
} from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon, GetApp as GetAppIcon } from '@mui/icons-material'
import React, { type ChangeEvent, type KeyboardEvent } from 'react'
import { type ICustomerInTableList } from '../../interfaces/ICustomerInList'
import { NavLink, useNavigate } from 'react-router-dom'
import type { ResponseData, Result } from '../../interfaces/IRequest'
import { ReactComponent as Filter } from '../../assets/images/filter-add.svg'
import { ReactComponent as Download }from '../../assets/images/DownloadExcel.svg'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import {
    EngineerCols,
    LeaderCols,
    engineerRow,
    teamLeadRow,
    userCols,
    userColsADmin,
    usersRow,
    usersRowAdmin,
} from './constants'
import dashBoardServices from '../../services/cspServices'

interface Props {
    requestsList: ResponseData
    searchText?: string
    setSearchText: () => void
    openNewRequestModal: () => void
    handleOpenFilter: () => void
    page: number
    setPage: any
    setSearchField: any
}

export default function RequestsTable({
    requestsList,
    searchText,
    setSearchText,
    openNewRequestModal,
    handleOpenFilter,
    setSearchField,
    page,
    setPage,
}: Props) {
    const { user } = useAppSelector(userSelector)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText()
    }
    const large = useMediaQuery('(min-width:1200px)')

    const navigate = useNavigate()

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        console.log('searchText', searchText)
        setPage(value)
    }
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            handleChange(event as ChangeEvent<HTMLInputElement>)
        }
    }

    const handleClearSearch = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setSearchField('')
        e.preventDefault()
    }

    const handleExport = async() => {
        // Add your export logic here
        try {
            // Fetch the Excel file
            const response = await dashBoardServices.getExportedFileExcel();
            
            // Create a Blob from the response data
            const blob = new Blob([response.data]);
    
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exported_file.xlsx';
    
            // Append the link to the document body and trigger the click event
            document.body.appendChild(link);
            link.click();
    
            // Clean up and remove the link
            document.body.removeChild(link);
    
    
            console.log('File downloaded successfully');
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    }

    return (
        <Stack
            flexGrow={1}
            spacing={3}
            sx={{
                paddingLeft: '32px',
                paddingRight: '32px',
                marginTop: '80px',
            }}
        >
            <Stack
                direction={large ? 'row' : 'column'}
                justifyContent={'space-between'}
            >
                <Stack direction={'row'} justifyContent={'center'} height={40}>
                    <TextField
                        size="small"
                        fullWidth
                        value={searchText}
                        onChange={(
                            e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) => setSearchField(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Search ...."
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                        style={{
                            backgroundColor: '#fff',
                            border: 'unset',
                            marginRight: '16px',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    onClick={handleClearSearch}
                                    edge="end"
                                >
                                    {searchText && <ClearIcon />}
                                </IconButton>
                            ),
                        }}
                    />
                    <Button
                        onClick={handleOpenFilter}
                        sx={{
                            borderRadius: '2px',
                            background: '#47A5A6',
                            display: 'inline-flex',
                            alignItems: 'flex-start',
                            '&:hover': {
                                background: '#47A5A6',
                            },
                        }}
                    >
                        <Filter width={25} height={25} />
                    </Button>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems={'center'}
                    maxHeight={40}
                    marginTop={'10px'}
                >
                   {(user.user_type === 'CUSTOMER' &&
                        user?.is_project_manager) || user.user_type === 'ADMIN' && (
                        <Button
                                disableRipple
                                 sx={{
                                    display: 'none', // TODO: change to inline-flex later
                                    // display: 'inline-flex',
                                    paddingInline: '20px',
                                    alignItems: 'flex-start',
                                    gap: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #494454',
                                    background: '#373737',
                                    textTransform: 'none',
                                    color: '#FFF',
                                    ':hover': {
                                        background: '#373737',
                                    },

                                }}
                                onClick={handleExport}
                            >
                                <Download />
                                Export as Excel
                            </Button>
                                        )}
                            
                       
                    {user.user_type === 'CUSTOMER' && (
                    <Button
                        disableRipple
                        sx={{
                            display: 'inline-flex',
                            padding: '10px 40px',
                            alignItems: 'center',
                            // width: '138px',
                            gap: '8px',
                            height: '40px',
                            borderRadius: '4px',
                            border: '1px solid #494454',
                            background: '#FFF',
                            textTransform: 'none',
                            marginTop: '10px',
                            maxHeight: 40,
                        }}
                        onClick={openNewRequestModal}
                    >
                        New Request
                    </Button>
                    )}
                   
                </Stack>
            </Stack>

            <Stack>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {(user.user_type === 'ENGINEER' &&
                                    user.is_leader) &&
                                    LeaderCols()}
                                 {user.user_type === 'ADMIN' &&
                                    LeaderCols()}
                                {user.user_type === 'ENGINEER' &&
                                    !user.is_leader &&
                                    EngineerCols()}
                                {user.user_type === 'CUSTOMER' &&
                                    !user.is_project_manager &&
                                    !user.is_admin &&
                                    userCols()}
                                {user.user_type === 'CUSTOMER' &&
                                    (user.is_project_manager ||
                                        user.is_admin) &&
                                    userColsADmin()}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requestsList.results.map((row: Result) => {
                                if (
                                    user.user_type === 'CUSTOMER' &&
                                    (user.is_project_manager || user.is_admin)
                                ) {
                                    return usersRowAdmin(row, navigate)
                                } else if (user.user_type === 'CUSTOMER')
                                    return usersRow(row, navigate)
                                if (
                                    user.user_type === 'ENGINEER' &&
                                    user.is_leader
                                )
                                    return teamLeadRow(row, navigate)
                                if (
                                    user.user_type === 'ENGINEER' &&
                                    !user.is_leader
                                )
                                    return engineerRow(row, navigate)
                               if (user.user_type === 'ADMIN')
                                 return teamLeadRow(row, navigate)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    <Pagination
                        count={Math.ceil(requestsList.count / 9)}
                        page={page}
                        onChange={handlePagination}
                        size="large"
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}
