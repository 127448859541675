import { Card, CardContent, Typography, Stack, styled } from '@mui/material'
import IncidentRequestInfoCard from './IncidentRequestInfoCard'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import IncidentStatusCard from './IncidentStatusCard'
import IncidentPriorityCard from './IncidentPriorityCard'

// -------------------------------------------------------------------------

const IncidentEffortWrapper = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#EAFFF6',
  padding: '8px',
  borderRadius: '4px',
})

const IncidentSLAWrapper = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F7F7F9',
  padding: '8px',
  borderRadius: '4px',
})

// -------------------------------------------------------------------------

type Props = {
  incidentStatus: string
  incidentEffortHours: string
  incidentPriority: string
  assignedTo: string
  openedAt: string
  incidentCategory: string
  incidentImpact: string
  lastUpdated: string
  responseTime: string
  resolvedTime: string
  escalationStatus: string
  bugRelated: string
  SLAResponseTime: string
  customerId: number
  resourceName?: 'INCIDENT' | 'TASK'
}

const IncidentSummaryCard = ({
  incidentStatus,
  incidentEffortHours,
  incidentPriority,
  assignedTo,
  openedAt,
  incidentCategory,
  incidentImpact,
  lastUpdated,
  responseTime,
  resolvedTime,
  escalationStatus,
  bugRelated,
  SLAResponseTime,
  customerId,
  resourceName = 'INCIDENT',
}: Props) => {
  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' | 'ADMIN' = user?.user_type

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" fontWeight="bold" color="common.black" mb={2}>
          Summary
        </Typography>

        <Stack direction="column" spacing={3}>
          <IncidentStatusCard
            currentStatus={incidentStatus}
            resourceName={resourceName}
          />

          <Stack direction="column" spacing={1}>
            <Typography color="common.black" fontWeight="bold">
              Efforts
            </Typography>

            <IncidentEffortWrapper>
              <Typography color="common.black">Total efforts</Typography>

              <Typography color="common.black" fontWeight="bold">
                {incidentEffortHours}
              </Typography>
            </IncidentEffortWrapper>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography color="common.black" fontWeight="bold">
              Priority
            </Typography>
            <IncidentPriorityCard
              currentPriority={incidentPriority}
              resourceName={resourceName}
              customerId={customerId}
            />
          </Stack>
          <IncidentRequestInfoCard
            userType={userType}
            assignedTo={assignedTo}
            openedAt={openedAt}
            incidentCategory={incidentCategory}
            incidentImpact={incidentImpact}
            lastUpdated={lastUpdated}
            responseTime={responseTime}
            resolvedTime={resolvedTime}
            escalationStatus={escalationStatus}
            bugRelated={bugRelated}
            resourceName={resourceName}
          />
          {userType === 'ENGINEER' && (
            <Stack direction="column" spacing={1}>
              <Typography color="common.black" fontWeight="bold">
                SLA Information
              </Typography>

              <IncidentSLAWrapper>
                <Typography color="common.black">Response Time</Typography>

                <Typography color="common.black" fontWeight="bold">
                  {SLAResponseTime}
                </Typography>
              </IncidentSLAWrapper>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default IncidentSummaryCard
