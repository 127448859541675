import { Typography, Stack, styled, Button } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { useState } from 'react'
import ReassignDialog from '../shared/ReassignDialog'

// ----------------------------------------------------------------

const IncidentInfoWrapper = styled(Stack)({
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: '#F7F7F9',
  padding: 6,
  borderRadius: 1,
})

// ----------------------------------------------------------------

type Props = {
  userType: 'CUSTOMER' | 'ENGINEER' | 'ADMIN'
  assignedTo: string
  openedAt: string
  incidentCategory: string
  incidentImpact: string
  lastUpdated: string
  responseTime: string
  resolvedTime: string
  escalationStatus: string
  bugRelated: string
  resourceName?: 'INCIDENT' | 'TASK'
}

const IncidentRequestInfoCard = ({
  userType,
  assignedTo,
  openedAt,
  incidentCategory,
  incidentImpact,
  lastUpdated,
  responseTime,
  resolvedTime,
  escalationStatus,
  bugRelated,
  resourceName,
}: Props) => {
  const [openReassignDialog, setOpenReassignDialog] = useState(false)

  const handleOpen = () => setOpenReassignDialog(true)
  const handleClose = () => setOpenReassignDialog(false)
  return (
    <>
      <Stack direction="column" spacing={1}>
        <Typography color="common.black" fontWeight="bold">
          Request Information
        </Typography>
        <IncidentInfoWrapper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography flex={1} color="#929EAE">
              Assigned to:
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Typography>{assignedTo}</Typography>
              {(userType === 'ENGINEER' || userType === 'ADMIN') && (
                <Button
                  onClick={handleOpen}
                  variant="text"
                  endIcon={<ChevronRight />}
                  sx={{
                    color: (theme) => theme.palette.teal.main,
                    textDecorationLine: 'underline',
                  }}
                >
                  Reassign
                </Button>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Opened At:
            </Typography>

            <Typography flex={1}>{openedAt}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Category:
            </Typography>

            <Typography flex={1}>{incidentCategory}</Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Impact:
            </Typography>

            <Typography flex={1}>{incidentImpact}</Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Last Updated:
            </Typography>

            <Typography flex={1}>{lastUpdated}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Response Time:
            </Typography>

            <Typography flex={1}>{responseTime}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Resolved Time:
            </Typography>

            <Typography flex={1}>{resolvedTime}</Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Escalation status:
            </Typography>

            <Typography flex={1}>{escalationStatus}</Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography flex={1} color="#929EAE">
              Bug Related:
            </Typography>

            <Typography flex={1}>{bugRelated}</Typography>
          </Stack>
        </IncidentInfoWrapper>
      </Stack>

      <ReassignDialog
        isOpen={openReassignDialog}
        handleClose={handleClose}
        resourceName={resourceName}
        currentAssignedEngineer={assignedTo}
      />
    </>
  )
}

export default IncidentRequestInfoCard
