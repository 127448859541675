import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Box,
  Chip,
  styled,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AdjustIcon from '@mui/icons-material/Adjust'
import useAddIncidentNote from '../IncidentNote/useAddIncidentNote'
import AddNoteDialog from '../IncidentNote/AddNoteDialog'
import ExpandableText from '../shared/ExpandableText'

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.teal.main,
  backgroundColor: '#EDF6F6',
  borderRadius: '4px',
  padding: '22px',
}))

type Props = {
  taskId: string
  taskTitle: string
  taskDescription: string
  taskOwner: string
  taskStatus: string
  taskLastUpdated: string
  customerName: string
  customerEmail: string
  customerPhone: string
  customerLocation: string
  shouldAddNote: boolean
}

const TaskBasicInfoCard = ({
  taskId,
  taskTitle,
  taskDescription,
  taskOwner,
  taskStatus,
  taskLastUpdated,
  customerName,
  customerEmail,
  customerPhone,
  customerLocation,
  shouldAddNote,
}: Props) => {
  const { handleOpen, isOpen, handleClose } = useAddIncidentNote()
  return (
    <>
      <Card>
        <Stack direction="column" spacing={1}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                spacing={{
                  xs: 1,
                  md: 3,
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    color: (theme) => theme.palette.teal.main,
                  }}
                >
                  {taskId}
                </Typography>

                <ExpandableText
                  text={taskTitle}
                  variant="h5"
                  maxChars={60}
                  showToggleButton={false}
                />
              </Stack>

              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  handleOpen()
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.teal.main,
                }}
              >
                Add Note
              </Button>

              {/* <StyledChip label={taskStatus} /> */}
            </Stack>
          </CardContent>

          <Divider flexItem />
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap="wrap"
                gap={1}
                paddingRight={4}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    Owner:
                  </Typography>{' '}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                    }}
                    display="inline-block"
                  >
                    {taskOwner}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    Customer Name:
                  </Typography>{' '}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                    }}
                    display="inline-block"
                  >
                    {customerName}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    Email:
                  </Typography>{' '}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                    }}
                    display="inline-block"
                  >
                    {customerEmail}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    Phone:
                  </Typography>{' '}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                    }}
                    display="inline-block"
                  >
                    {customerPhone}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    Location:
                  </Typography>{' '}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                    }}
                    display="inline-block"
                  >
                    {customerLocation}
                  </Typography>
                </Box>
              </Stack>

              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
                alignItems="baseline"
                paddingRight={4}
              >
                <Stack direction="row" alignItems="center">
                  <AdjustIcon
                    sx={{
                      mr: 1,
                      width: 16,
                      height: 16,
                      color: (theme) => theme.palette.teal.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      color: (theme) => theme.palette.teal.main,
                    }}
                  >
                    Description:
                  </Typography>
                </Stack>
                <ExpandableText text={taskDescription} />
              </Stack>
            </Stack>
          </CardContent>
        </Stack>
      </Card>
      <AddNoteDialog isOpen={isOpen} handleClose={handleClose} />
    </>
  )
}

export default TaskBasicInfoCard
