import { useState } from 'react'
import { useTabs } from '../../hooks/useTabs'

type NoteTab = {
  value: 'private-note' | 'customer-note'
  label: string
}

const TABS: NoteTab[] = [
  {
    value: 'private-note',
    label: 'Private Note',
  },
  {
    value: 'customer-note',
    label: 'Note to customer',
  },
]
const defaultTab = TABS[0].value

const useAddIncidentNote = () => {
  const [openAddNoteDialog, setAddNoteDialog] = useState(false)

  const tabs = useTabs(defaultTab)

  const handleOpen = () => setAddNoteDialog(true)
  const handleClose = () => setAddNoteDialog(false)

  return {
    isOpen: openAddNoteDialog,
    handleOpen,
    handleClose,
    tabs,
    TABS,
  }
}

export default useAddIncidentNote
