import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import { Stack } from '@mui/system'
import { ReactComponent as RedFlag } from '../../assets/images/RedFlag.svg'
import { ReactComponent as YellowFlag } from '../../assets/images/yellowFlag.svg'
import { titleCase } from '../../helper/utils'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#494454',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'capitalize',

    '&:not(:first-child)': {
      // backgroundColor: theme.palette.action.hover,
      color: '#000',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      textTransform: 'capitalize',
    },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const usersRow = (row: any, navigate: any) => {
  console.log('users row', row)
  return (
    <StyledTableRow
      key={row.id}
      onClick={() => {
        if (row.id) navigate(`/incidents/${row.id}`)
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(94, 94, 94, 0.10)',
          border: '1px solid #267677',
          cursor: 'pointer',
        },
      }}
    >
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>
          {row.yellow_flag && (
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              sx={{ marginX: '9px' }}
            >
              <YellowFlag />
            </Stack>
          )}
          {row.red_flag && <RedFlag />}
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'} justifyContent={'center'}>
          {row.id}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">{row.subject}</StyledTableCell>
      <StyledTableCell align="right">
        {titleCase(row.status)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.product.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.priority.priority_name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.assigned_to.user.full_name}
      </StyledTableCell>
      <StyledTableCell align="right">{row.bug_related}</StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.site.name}
      </StyledTableCell>
    </StyledTableRow>
  )
}
export const usersRowAdmin = (row: any, navigate: any) => {
  console.log('usersRowAdmin', row)

  return (
    <StyledTableRow
      key={row.id}
      onClick={() => {
        if (row.id) navigate(`/incidents/${row.id}`)
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(94, 94, 94, 0.10)',
          border: '1px solid #267677',
          cursor: 'pointer',
        },
      }}
    >
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>
          {row.yellow_flag && (
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              sx={{ marginX: '9px' }}
            >
              <YellowFlag />
            </Stack>
          )}
          {row.red_flag && <RedFlag />}
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'} justifyContent={'center'}>
          {row.id}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">{row.subject}</StyledTableCell>
      <StyledTableCell align="right">
        {titleCase(row.status)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.product.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.priority.priority_name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.assigned_to.user.full_name}
      </StyledTableCell>
      <StyledTableCell align="right">{row.bug_related}</StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.site.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row?.created_by?.user?.full_name}
      </StyledTableCell>
    </StyledTableRow>
  )
}
export const teamLeadRow = (row: any, navigate: any) => {
  console.log('team lead row', row)
  return (
    <StyledTableRow
      key={row.id}
      onClick={() => {
        if (row.id)
          navigate(`/incidents/${row.id}`)
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(94, 94, 94, 0.10)',
          cursor: 'pointer',
          border: '1px solid #267677',
        },
      }}
    >
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>
          {row.yellow_flag && (
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              sx={{ marginX: '9px' }}
            >
              <YellowFlag />
            </Stack>
          )}
          {row.red_flag && <RedFlag />}
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>{row.id}</Stack>
      </StyledTableCell>
      <StyledTableCell align="right">{row.subject}</StyledTableCell>
      <StyledTableCell align="right">
        {titleCase(row.status)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.created_by.user?.full_name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.product?.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.assigned_to.team.team?.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.assigned_to.user.full_name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.priority.priority_name}
      </StyledTableCell>
      <StyledTableCell align="right">{row.bug_related}</StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.site?.name}
      </StyledTableCell>
    </StyledTableRow>
  )
}
export const engineerRow = (row: any, navigate: any) => {
  return (
    <StyledTableRow
      key={row.id}
      onClick={() => {
        if (row.id)
          navigate(`/incidents/${row.id}`)
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(94, 94, 94, 0.10)',
          cursor: 'pointer',
          border: '1px solid #267677',
        },
      }}
    >
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>
          {row.yellow_flag && (
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              sx={{ marginX: '9px' }}
            >
              <YellowFlag />
            </Stack>
          )}
          {row.red_flag && <RedFlag />}
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Stack direction={'row'}>{row.id}</Stack>
      </StyledTableCell>
      <StyledTableCell align="right">{row.subject}</StyledTableCell>
      <StyledTableCell align="right">
        {titleCase(row.status)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.created_by.customer?.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.product.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.assigned_to.team.team.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {row.priority.priority_name}
      </StyledTableCell>
      <StyledTableCell align="right">{row.bug_related}</StyledTableCell>
      <StyledTableCell align="right">
        {row.related_to.customer_product.site.name}
      </StyledTableCell>
    </StyledTableRow>
  )
}

export const userCols = () => {
  return (
    <>
      <StyledTableCell align="right" width={'20px'}></StyledTableCell>
      <StyledTableCell align="right">Request Id</StyledTableCell>
      <StyledTableCell>Subject</StyledTableCell>
      <StyledTableCell align="right">Status</StyledTableCell>
      <StyledTableCell align="right">Product</StyledTableCell>
      <StyledTableCell align="right">Priority</StyledTableCell>
      <StyledTableCell align="right">Assigned to</StyledTableCell>
      <StyledTableCell align="right">Bug related</StyledTableCell>
      <StyledTableCell align="right">Site</StyledTableCell>
    </>
  )
}

export const userColsADmin = () => {
  return (
    <>
      <StyledTableCell align="right" width={'20px'}></StyledTableCell>
      <StyledTableCell align="right">Request Id</StyledTableCell>
      <StyledTableCell>Subject</StyledTableCell>
      <StyledTableCell align="right">Status</StyledTableCell>
      <StyledTableCell align="right">Product</StyledTableCell>
      <StyledTableCell align="right">Priority</StyledTableCell>
      <StyledTableCell align="right">Assigned to</StyledTableCell>
      <StyledTableCell align="right">Bug related</StyledTableCell>
      <StyledTableCell align="right">Site</StyledTableCell>
      <StyledTableCell align="right">Opened By</StyledTableCell>
    </>
  )
}

export const EngineerCols = () => {
  return (
    <>
      <StyledTableCell align="right"></StyledTableCell>
      <StyledTableCell align="right">Request Id</StyledTableCell>
      <StyledTableCell>Subject</StyledTableCell>
      <StyledTableCell align="right">Status</StyledTableCell>
      <StyledTableCell align="right">Customer</StyledTableCell>
      <StyledTableCell align="right">Product</StyledTableCell>
      <StyledTableCell align="right">Assigned Team</StyledTableCell>
      <StyledTableCell align="right">Priority</StyledTableCell>
      <StyledTableCell align="right">Bug</StyledTableCell>
      <StyledTableCell align="right">Site</StyledTableCell>
    </>
  )
}

export const LeaderCols = () => {
  return (
    <>
      <StyledTableCell align="right"></StyledTableCell>
      <StyledTableCell align="right">Request Id</StyledTableCell>
      <StyledTableCell>Subject</StyledTableCell>
      <StyledTableCell align="right">Status</StyledTableCell>
      <StyledTableCell align="right">Customer</StyledTableCell>
      <StyledTableCell align="right">Product</StyledTableCell>
      <StyledTableCell align="right">Assigned Team</StyledTableCell>
      <StyledTableCell align="right">Assigned Engineer</StyledTableCell>
      <StyledTableCell align="right">Priority</StyledTableCell>
      <StyledTableCell align="right">Bug</StyledTableCell>
      <StyledTableCell align="right">Site</StyledTableCell>
    </>
  )
}
export const emptyResForLead = {
  count: 5,
  next: null,
  previous: null,
  results: [
    {
      id: null,
      subject: '------',
      status: '------',
      created_by: { customer: { id: null, name: '------' } },
      related_to: {
        customer_product: {
          id: 18,
          product: { id: 2, name: '------' },
          site: { id: 16, name: '------' },
        },
      },
      assigned_to: {
        id: 22,
        user: {
          email: '------',
          full_name: '------',
          jop_title: '------',
          phone: '------',
          is_active: true,
        },
        skills: [],
        team: {
          team: {
            id: 1,
            name: '------',
            description: '------',
          },
          is_leader: true,
        },
        is_online: true,
      },
      priority: {
        id: 121,
        priority_name: '------',
        priority_description: '------',
        response_time: null,
        resolution_time: null,
        is_applicable: true,
        priority: 1,
      },
      bug_related: '------',
      red_flag: false,
      yellow_flag: false,
    },
  ],
}
