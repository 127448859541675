import { isEmpty, map } from 'lodash'
import { useState, useEffect, useCallback } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
    type SxProps,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormGroup,
    RadioGroup,
    Radio,
    FormControl,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useForm, Controller, useWatch } from 'react-hook-form'
import { ReactComponent as Add } from '../../../assets/images/iconAdd.svg'
import { ReactComponent as Pdf } from '../../../assets/images/pdf.svg'
import { LoadingButton } from '@mui/lab'
import AttachmentFile from '../../common/AttachmentFileComponent/AttachmentFile'
import FilterItem from './FilterItem'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import FilterPriorities from './Priorities'
import StatusFilter from './StatusFilter'
import { useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user'
import { useWindowSize } from 'usehooks-ts'
import SLAFilter from './SLAFilter'

interface IRequestModalFilterModal {
    open: boolean
    filtersSelected: boolean
    setFilters: any
    filters: any
    handleClose: () => void
    setFiltersSelected: (state: boolean) => void
    handleFetch: (str: string) => void
    createdByFilter?: any
    setCreatedByFilter?: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

interface FormData {
    subject?: string
    product?: string
    RelatedTo?: string
    priority?: string
    description?: string
    attachments?: File[]
    bug_related?: string
    'Delayed Response'?: any
    'Overdue Resolution'?: any
    'Opened By'?: any
}
interface IStyles {
    title: SxProps
    textField: SxProps
    attachments: SxProps
}

const RequestModalFilter = ({
    handleClose,
    open,
    handleFetch,
    setFiltersSelected,
    filtersSelected,
    setFilters,
    filters,
    createdByFilter,
    setCreatedByFilter,
}: IRequestModalFilterModal) => {
    const [expanded, setExpanded] = useState<string | false>(false)
    const [valueBug, setBugValue] = useState('')
    const { user } = useAppSelector(userSelector)
    console.log('user', user)
    const [products, setProducts] = useState<any>([])
    const [priority, setPriority] = useState<any>([])
    const { width, height } = useWindowSize()

    const {
        data: productsData,
        isPending,
        error,
    } = useFetch(
        dashBoardServices.getCustomerCategories,
        user.customer_id ?? undefined
    )

    const {
        data: priorityData,
        isPending: isPendingPriority,
        error: errorPriority,
    } = useFetch(
        dashBoardServices.getCustomerPriorities,
        user.customer_id ?? undefined
    )

    const handleBugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBugValue((event.target as HTMLInputElement).value)
    }

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>()
    const watchedFields = watch()
    const onsubmit = (data: any) => {
        // console.log('data', data)
        let searchString: string = '&'
        const filtersSelected = !isEmpty(data)

        setFiltersSelected(filtersSelected)
        Object.entries(data).map(([key, value]: any) => {
            const cValue: string = value
            if (key == 'Delayed Response' && cValue) {
                searchString += `delayed_response=${cValue}&`
                return
            }
            if (key == 'Overdue Resolution' && cValue) {
                searchString += `overdue_resolution=${cValue}&`
                return
            }
            if (key == 'Opened By') {
                searchString += `requestFilter=${cValue}&`
                return
            }
            if (value) {
                const regex = /productID(.+?)(?=\s|$)/g

                const matchPproduct = regex.exec(key)

                if (matchPproduct) {
                    const numberAfterID = matchPproduct[1].trim()
                    searchString += `related_to=${numberAfterID}&`
                    return
                }
                const regex_ = /PriorityID(.+?)(?=\s|$)/g
                const matchPriority = regex_.exec(key)
                if (matchPriority) {
                    const numberAfterID = matchPriority[1].trim()
                    searchString += `priority=${numberAfterID}&`
                    return
                }
                if (key == 'bug_related') {
                    // console.log('value', typeof value)
                    // console.log('value', value)
                    const val: string = value == 'true' ? 'YES' : 'NO'
                    // console.log('value', value)
                    if (value && value != 'undefined')
                        searchString += `bug_related=${val}&`
                    return
                }
                const tValue: string = key
                searchString += `status=${tValue}&`
            }
        })

        searchString = searchString.slice(0, -1)
        // console.log('searchString', searchString)
        handleFetch(searchString)
    }

    useEffect(() => {
        if (productsData && !error && productsData.length > 0) {
            setProducts(productsData)
        }
    }, [productsData])

    useEffect(() => {
        if (
            priorityData &&
            !errorPriority &&
            !isPendingPriority &&
            priorityData.length > 0
        ) {
            setPriority(priorityData[0].priorities)
        }
    }, [priorityData])
    useEffect(() => {
        console.log('watchedFields', watchedFields)
        Object.keys(watchedFields).map((key) => {
            if (
                // @ts-expect-error skip
                watchedFields[key] != undefined &&
                // @ts-expect-error skip
                watchedFields[key] &&
                // @ts-expect-error skip
                watchedFields[key] != 'undefined' &&
                key != 'bug_related'
            ) {
                setFiltersSelected(true)
            }
            if (watchedFields.bug_related != 'undefined') {
                setFiltersSelected(true)
            }
        })
    }, [watchedFields])

    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        maxHeight: '700px',
                        // maxHeight: chartDimensions,
                        overflow: 'auto',
                        p: 3,
                    }}
                >
                    <CommonTypography
                        sx={{
                            marginTop: '10px',
                            marginLeft: '10px',
                            marginBottom: '48px',
                            color: '#000',
                            fontFamily: 'Inter',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                        variant={'h6'}
                    >
                        Add Filter
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <Stack direction={'column'} spacing={1}>
                            <FilterItem control={control} items={products} />
                            <FilterPriorities
                                control={control}
                                items={priority}
                            />
                            <StatusFilter
                                control={control}
                                items={[
                                    'OPENED',
                                    'ASSIGNED',
                                    'IN_PROGRESS',
                                    'WAITING_FOR_CUSTOMER',
                                    'WAITING_VENDOR_REPLY',
                                    'SOLVED',
                                    'CLOSED',
                                    'PERMANENTLY_CLOSED',
                                ]}
                            />
                            {user?.is_project_manager ||
                            user?.user_type === 'ENGINEER' ? (
                                <SLAFilter
                                    control={control}
                                    items={[
                                        'Overdue Resolution',
                                        'Delayed Response',
                                    ]}
                                    setFilters={setFilters}
                                    filters={filters}
                                />
                            ) : undefined}
                        </Stack>
                        {user?.is_project_manager || user?.is_admin ? (
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Typography sx={styles.title}>
                                    Opened By
                                </Typography>
                                <FormControl
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        columnGap: '12px',
                                    }}
                                >
                                    <Controller
                                        name="Opened By"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                {...field}
                                                // value={createdByFilter}
                                                // onChange={(e) => {
                                                //     setCreatedByFilter(e.target.value)
                                                //     console.log(e.target.value)
                                                // }}
                                            >
                                                <FormControlLabel
                                                    value={'me'}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                marginRight:
                                                                    '12px',
                                                            }}
                                                        />
                                                    }
                                                    label="Me"
                                                />
                                                <FormControlLabel
                                                    value={'others'}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                marginRight:
                                                                    '12px',
                                                            }}
                                                        />
                                                    }
                                                    label="Others"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                        ) : undefined}
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography sx={styles.title}>
                                bug related
                            </Typography>
                            <FormControl>
                                <Controller
                                    name="bug_related"
                                    control={control}
                                    defaultValue="undefined"
                                    render={({ field }) => (
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            {...field}
                                        >
                                            <FormControlLabel
                                                value={'false'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            marginRight: '12px',
                                                        }}
                                                    />
                                                }
                                                label="No"
                                            />
                                            <FormControlLabel
                                                value={'true'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            marginRight: '35px',
                                                            marginLeft: '0px',
                                                        }}
                                                    />
                                                }
                                                label="Yes"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Stack>

                        <Stack
                            sx={{ marginTop: '48px' }}
                            direction={'row'}
                            spacing={3}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={handleClose}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={() => {
                                    // reset()
                                    Object.keys(watchedFields).map(
                                        (key: any) => {
                                            console.log(
                                                'watchedFields',
                                                watchedFields
                                            )
                                            setValue(key, false)
                                        }
                                    )
                                    setValue('bug_related', 'undefined')
                                    setValue('Opened By', '')
                                    setCreatedByFilter('')
                                    setFiltersSelected(false) // Reset the filter selection state
                                }}
                                disabled={!filtersSelected} // Disable the button when no filters are selected
                            >
                                {'reset'}
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant={'contained'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    boxShadow: 'none',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    borderRadius: '4px',
                                }}
                                // onClick={handleSubmit(onSubmit)}
                                loading={false}
                            >
                                Apply
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles: IStyles = {
    textField: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        width: '838px',
        marginBottom: '22px',
    },
    title: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'capitalize',
        marginLeft: '14px',
    },
    attachments: {
        width: '838px',
        minHeight: '100px',
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        padding: '9px 12px 12px 9px',
    },
}
export default RequestModalFilter
