import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TableRowsLoader from '../../../common/TableRowsLoader'
import useIncidentHistory from './useIncidentHistory'
import { userSelector } from '../../../../features/user'
import { useAppSelector } from '../../../../app/hooks'
import ExpandableText from '../../../shared/ExpandableText'

const IncidentHistory = () => {
  const { incidentHistory, loading } = useIncidentHistory()
  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' | 'ADMIN' = user?.user_type

  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actioned By</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                {(userType === 'ENGINEER' || userType === 'ADMIN') && (
                  <>
                    <TableCell>Effort</TableCell>
                    <TableCell>Email</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRowsLoader numberOfRows={5} numberOfCells={5} />
              ) : (
                incidentHistory.map((incident, index) => (
                  <TableRow key={index}>
                    <TableCell>{incident.actioned_by.full_name}</TableCell>
                    <TableCell>
                      <ExpandableText text={incident.description} />
                    </TableCell>
                    <TableCell>{incident.created_at}</TableCell>
                    {(userType === 'ENGINEER' || userType === 'ADMIN') && (
                      <>
                        <TableCell>{incident.effort}</TableCell>
                        <TableCell>
                          {incident.email_sent ? (
                            <CheckIcon
                              sx={{
                                color: '#12A3B0',
                              }}
                            />
                          ) : (
                            <CloseIcon sx={{ color: '#FF5252' }} />
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default IncidentHistory
