import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  TextField,
  FormGroup,
  InputLabel,
  DialogActions,
  Button,
  Stack,
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import dayjs, { type Dayjs } from 'dayjs'
import toast from 'react-hot-toast'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { LoadingButton } from '@mui/lab'
import { useFileAttachments } from '../Modals/NewRequestModal/useFileAttachments'
import AttachmentSection from '../Modals/NewRequestModal/AttachmentSection'
import { UserData } from './types'
import UserProfileItem from './UserProfileItem'
import { apiClient } from '../../services/cspServices'

const schema = z.object({
  to: z
    .array(
      z.object({
        id: z.number(),
        full_name: z.string(),
        email: z.string().email(),
      })
    )
    .optional(),
  sendCustomerEmail: z.boolean().default(false),
  cc: z
    .array(
      z.object({
        id: z.number(),
        full_name: z.string(),
        email: z.string().email(),
      })
    )
    .optional(),
  bcc: z
    .array(
      z.object({
        id: z.number(),
        full_name: z.string(),
        email: z.string().email(),
      })
    )
    .optional(),
  description: z.string().min(1, 'Description is required'),
  effort: z
    .instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Please enter a valid time in this format HH:mm',
    })
    .refine((val) => val.isValid(), {
      message: 'Please enter a valid time in this format HH:mm',
    }),
})

type FormData = z.infer<typeof schema>

type Props = {
  handleClose: () => void
}

const CustomerNoteForm = ({ handleClose }: Props) => {
  const { id: incidentId } = useParams<{ id: string }>()

  const [customers, setCustomers] = useState<UserData[]>([])

  useEffect(() => {
    // Will return engineers and customer users for the incident
    const retrieveIncidentUsers = async (incidentId: string) => {
      try {
        const res = await apiClient.get(
          `v2/query/users?request_id=${incidentId}`
        )
        console.log('Users:', res.data)
        setCustomers(res.data)
      } catch (error) {
        console.error('Error fetching Users:', error)
      }
    }

    retrieveIncidentUsers(incidentId as string)
  }, [incidentId])

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      to: [],
      sendCustomerEmail: false,
      cc: [],
      bcc: [],
      description: '',
      effort: undefined,
    },
    resolver: zodResolver(schema),
  })
  const currentTO = watch('to')
  const currentCC = watch('cc')
  const currentBCC = watch('bcc')

  const disableSendEmail =
    !currentTO?.length && !currentCC?.length && !currentBCC?.length

  useEffect(() => {
    if (
      currentTO?.length === 0 &&
      currentCC?.length === 0 &&
      currentBCC?.length === 0
    ) {
      setValue('sendCustomerEmail', false)
    }
  }, [currentTO, currentCC, currentBCC, setValue])

  const {
    fileLoading,
    attachmentFiles,
    deleteFileAttachment,
    handleFileUpload,
  } = useFileAttachments('NOTE')

  const mapFormDataToRequestPayload = (data: FormData) => ({
    ...data,
    to: data.to?.map((user) => user.id) ?? [],
    cc: data.cc?.map((user) => user.id) ?? [],
    bcc: data?.bcc?.map((user) => user.id) ?? [],
    effort: data.effort.format('HH:mm'),
    attachments: attachmentFiles.map((file) => file.id),
    email_sent: data.sendCustomerEmail,
    internal: false,
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = mapFormDataToRequestPayload(data)
    console.log('Submitted data:', payload)
    try {
      const res = await apiClient.post(
        `v2/service/request/${incidentId}/create-note/`,
        payload
      )

      console.log('response:', res.data)

      toast.success('Note created successfully')
      reset()
      handleClose()
      // TODO: call the API that fetch history instead
      // reload to fetch data from the backend
      window.location.reload()
    } catch (error) {
      console.error('Error creating note:', error)
      toast.error('Something went wrong while creating the note')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="to" error={!!errors.to}>
                  To
                </InputLabel>

                <Autocomplete
                  id="to"
                  {...field}
                  multiple
                  options={customers}
                  getOptionLabel={(option) =>
                    `${option.full_name} (${option.email})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value)}
                  renderTags={(value: UserData[], getTagProps) =>
                    value.map((option: UserData, index: number) => (
                      <Chip
                        variant="outlined"
                        label={`${option.email}`}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <UserProfileItem
                      name={option.full_name}
                      email={option.email}
                      props={props}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!errors.to}
                      helperText={errors.to ? errors.to.message : ''}
                    />
                  )}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="cc"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="cc" error={!!errors.cc}>
                  CC
                </InputLabel>

                <Autocomplete
                  id="cc"
                  {...field}
                  multiple
                  options={customers}
                  getOptionLabel={(option) =>
                    `${option.full_name} (${option.email})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value)}
                  renderTags={(value: UserData[], getTagProps) =>
                    value.map((option: UserData, index: number) => (
                      <Chip
                        variant="outlined"
                        label={`${option.email}`}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <UserProfileItem
                      name={option.full_name}
                      email={option.email}
                      props={props}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!errors.cc}
                      helperText={errors.cc ? errors.cc.message : ''}
                    />
                  )}
                />
              </FormGroup>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="bcc"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="bcc" error={!!errors.bcc}>
                  BCC
                </InputLabel>

                <Autocomplete
                  id="bcc"
                  {...field}
                  multiple
                  options={customers}
                  getOptionLabel={(option) =>
                    `${option.full_name} (${option.email})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value)}
                  renderTags={(value: UserData[], getTagProps) =>
                    value.map((option: UserData, index: number) => (
                      <Chip
                        variant="outlined"
                        label={`${option.email}`}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <UserProfileItem
                      name={option.full_name}
                      email={option.email}
                      props={props}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!errors.bcc}
                      helperText={errors.bcc ? errors.bcc.message : ''}
                    />
                  )}
                />
              </FormGroup>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="description" error={!!errors.description}>
                  Description
                </InputLabel>

                <TextField
                  {...field}
                  id="description"
                  fullWidth
                  size="small"
                  multiline
                  rows={8}
                  error={!!errors.description}
                  helperText={
                    !!errors.description && errors.description.message
                  }
                />
              </FormGroup>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="effort"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="effort" error={!!errors.effort}>
                  Effort
                </InputLabel>

                <TimeField
                  {...field}
                  id="effort"
                  format="HH:mm"
                  fullWidth
                  size="small"
                  inputProps={{
                    placeholder: 'HH:mm',
                  }}
                  slotProps={{
                    textField: {
                      error: !!errors.effort,
                      helperText: !!errors.effort && errors.effort.message,
                    },
                  }}
                />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>

      <AttachmentSection
        attachmentFiles={attachmentFiles}
        handleDeleteRequest={deleteFileAttachment}
        handleFileUpload={handleFileUpload}
        fileLoading={fileLoading}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mt: 4,
        }}
      >
        <Controller
          name="sendCustomerEmail"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="success"
                    disabled={disableSendEmail}
                    {...field}
                    checked={field.value}
                  />
                }
                label="Send email"
              />
            </FormGroup>
          )}
        />

        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            loading={isSubmitting}
            disabled={isSubmitting || fileLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Stack>
    </form>
  )
}

export default CustomerNoteForm
