import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import { IncidentHistory } from './types'
import { apiClient } from '../../../../services/cspServices'

const useIncidentHistory = () => {
  const { id: incidentId } = useParams<{ id: string }>()

  const [incidentHistory, setIncidentHistory] = useState<IncidentHistory[]>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchIncidentHistory = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<IncidentHistory[]> = await apiClient.get(
          `/v2/repo/request/${incidentId}/history`
        )
        console.log('Incident history:', res.data)
        setIncidentHistory(res.data)
      } catch (error) {
        console.error('Error fetching incident history:', error)
        toast.error('Error fetching incident history')
      } finally {
        setLoading(false)
      }
    }
    fetchIncidentHistory()
  }, [incidentId])

  return {
    incidentHistory,
    loading,
  }
}

export default useIncidentHistory
