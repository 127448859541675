import { Stack, Alert } from '@mui/material'
import TaskFileCard from './TaskFileCard'
import IncidentTaskCardSkeleton from '../../../IncidentDetails/IncidentTabs/IncidentTasks/IncidentTaskCardSkeleton'
import { TaskFile } from './types'

type Props = {
  taskFiles: TaskFile[]
  selectedFiles: TaskFile[]
  loading: boolean
  toggleFileSelection: (file: TaskFile) => void
}

const TaskFiles = ({
  taskFiles,
  selectedFiles,
  loading,
  toggleFileSelection,
}: Props) => {
  if (!taskFiles.length && !loading) {
    return (
      <Alert
        variant="filled"
        severity="error"
        sx={{
          color: 'common.white',
        }}
      >
        There are no files for this task
      </Alert>
    )
  }
  return (
    <Stack direction="column" spacing={3}>
      {loading
        ? [...Array(5)].map((key) => <IncidentTaskCardSkeleton key={key} />)
        : taskFiles.map((file) => (
            <TaskFileCard
              key={file.id}
              taskFile={file}
              isSelected={selectedFiles.some((f) => f.id === file.id)}
              onToggleSelect={toggleFileSelection}
            />
          ))}
    </Stack>
  )
}

export default TaskFiles
