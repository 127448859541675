import {
  Stack,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  Chip,
  styled,
  // Button,
  Divider,
} from '@mui/material'
import { IncidentTask } from './types'
import { useNavigate, useParams } from 'react-router-dom'

// const OutlinedButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.teal.main,
//   borderColor: theme.palette.teal.main,
//   padding: '8px 32px',
//   transition: 'all 0.3s ease-in-out',

//   '&:hover': {
//     backgroundColor: theme.palette.teal.main,
//     borderColor: theme.palette.teal.main,
//     color: '#fff',
//   },
// }))

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.teal.main,
  backgroundColor: '#EDF6F6',
  borderRadius: '4px',
  padding: '22px',
}))

type Props = {
  task: IncidentTask
}

const IncidentTaskCard = ({ task }: Props) => {
  const navigate = useNavigate()
  const { id: incidentId } = useParams<{ id: string }>()
  const handleCardClick = () =>
    navigate(`/incidents/${incidentId}/tasks/${task?.id}`)
  return (
    <Card>
      <CardActionArea
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleCardClick}
      >
        <Stack direction="column" spacing={1}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" color="common.black" fontWeight="bold">
                {task?.title}
              </Typography>

              <Stack direction="row" spacing={2}>
                <StyledChip label={task?.status} />

                {/* <OutlinedButton
                sx={{
                  pointerEvents: 'none',
                }}
                variant="outlined"
              >
                Effort 01:11
              </OutlinedButton> */}
              </Stack>
            </Stack>
          </CardContent>

          <Divider flexItem />

          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  display="inline-block"
                >
                  ID:
                </Typography>{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[400],
                  }}
                  display="inline-block"
                >
                  {task?.id}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  display="inline-block"
                >
                  Owner:
                </Typography>{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[400],
                  }}
                  display="inline-block"
                >
                  {task?.created_by?.user?.full_name}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  display="inline-block"
                >
                  Customer Name:
                </Typography>{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[400],
                  }}
                  display="inline-block"
                >
                  {task?.customer?.user?.full_name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  display="inline-block"
                >
                  Opened Date:
                </Typography>{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[400],
                  }}
                  display="inline-block"
                >
                  {task?.created_at}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default IncidentTaskCard
