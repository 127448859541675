const baseURL = () => {
  // const url: string = window.location.hostname;
  // const globalUrl: string = 'http://81.208.171.89:8000/' // stagging
  // const globalUrl: string = 'http://81.208.171.89:8001/' // stagging
  // const globalUrl: string = 'http://81.208.171.89:8005/' //  dev
  const globalUrl: string = 'https://csp.it-ranks.com/' // production
  // const globalUrl: string = 'http://193.122.68.192:8001/'
  // const globalUrl: string = 'http://150.230.52.110:8000/'
  // const globalUrl: string = 'http://150.230.52.110:8001/'
  return { globalUrl }
}

export default baseURL