import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import RequestForm from './RequestForm'
import AttachmentSection from './AttachmentSection'
import { useNewIncidentForm } from './useNewIncidentForm'
import { useFetchCustomerData } from './useFetchCustomerData'
import { useFileAttachments } from './useFileAttachments'

type Props = {
  open: boolean
  handleClose: () => void
}

const NewIncidentModal: React.FC<Props> = ({ handleClose, open }) => {
  const {
    fileLoading,
    attachmentFiles,
    setAttachmentFiles,
    deleteFileAttachment,
    handleFileUpload,
  } = useFileAttachments()

  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    setValue,
    selectedCategoryId,
    loading,
    reset,
  } = useNewIncidentForm(attachmentFiles)

  const { categories, priorities, subCategories, setSubCategories } =
    useFetchCustomerData()

  const handleModalClose = () => {
    reset()
    setAttachmentFiles([])
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h5" fontWeight="bold" color="common.black" mb={3}>
        Create Incident
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RequestForm
            control={control}
            errors={errors}
            setValue={setValue}
            selectedCategoryId={selectedCategoryId}
            categories={categories}
            priorities={priorities}
            subCategories={subCategories}
            setSubCategories={setSubCategories}
          />
          <AttachmentSection
            attachmentFiles={attachmentFiles}
            handleDeleteRequest={deleteFileAttachment}
            handleFileUpload={handleFileUpload}
            fileLoading={fileLoading}
          />
          <DialogActions
            sx={{
              mt: 4,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              loading={loading}
              disabled={loading || fileLoading}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default NewIncidentModal
