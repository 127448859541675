import { Stack, Box, styled, IconButton, Typography } from '@mui/material'
import LoadingSpinner from '../../components/shared/LoadingSpinner'
import useTaskDetails from '../../components/TaskDetails/useTaskDetails'
import TaskBasicInfoCard from '../../components/TaskDetails/TaskBasicInfoCard'
import SummaryCard from '../../components/IncidentDetails/IncidentSummaryCard'
import useIncidentDetails from '../../components/IncidentDetails/useIncidentDetails'
import TaskTabsSection from '../../components/TaskDetails/IncidentTabs/TaskTabsSection'
import { ArrowBackIosNew } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const RootLayout = styled('div')({
  marginTop: '60px',
  width: '100%',
  minHeight: '100vh',
  display: 'grid',
  gridTemplateColumns: '5fr 2fr',
  gap: '24px',
  padding: '32px',

  '@media (max-width: 1024px)': {
    gridTemplateColumns: '1fr',
    padding: '16px',
  },
})

const SummaryBox = styled(Box)({
  position: 'sticky',
  top: '8px', // Adjust based on navbar height
  alignSelf: 'start',

  '@media (max-width: 1024px)': {
    position: 'static', // Remove stickiness on smaller screens
  },
})

const TaskDetails = () => {
  const { task, loading } = useTaskDetails()

  const navigate = useNavigate()

  const { incident, loading: incidentLoading } = useIncidentDetails()
  if (loading || incidentLoading) {
    return <LoadingSpinner />
  }
  return (
    <RootLayout>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={4}>
            <IconButton
              onClick={() => navigate(-1)}
              size="small"
              sx={{
                backgroundColor: '#33303b',
                color: '#fff',
                '&:hover': { backgroundColor: '#33303b' },
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
            <Typography variant="h6" fontWeight="bold">
              Task Details
            </Typography>
          </Stack>
          <TaskBasicInfoCard
            taskId={task?.id}
            taskTitle={task?.title}
            taskDescription={task?.description}
            taskOwner={task?.created_by?.user?.full_name}
            taskStatus={task?.status}
            taskLastUpdated={task?.created_at}
            customerName={task?.customer?.full_name}
            customerEmail={task?.customer?.email}
            customerPhone={task?.customer?.phone}
            customerLocation={incident?.category?.customer_product?.site?.name}
            shouldAddNote={false}
          />
        </Stack>
        <TaskTabsSection />
      </Stack>

      <SummaryBox>
        <SummaryCard
          customerId={incident?.customer_organization_id}
          incidentStatus={task?.status}
          incidentEffortHours={task?.total_effort_hours}
          incidentPriority={task?.priority}
          assignedTo={task?.engineer?.user?.full_name}
          openedAt={task?.created_at}
          incidentCategory={incident?.category?.customer_product?.product?.name}
          incidentImpact={incident?.impact}
          lastUpdated={incident?.updated_at}
          responseTime={incident?.response_time}
          resolvedTime={incident?.resolved_time}
          escalationStatus={incident?.escalation_status}
          bugRelated={incident?.bug_related}
          SLAResponseTime={incident?.sla_response_time}
          resourceName="TASK"
        />
      </SummaryBox>
    </RootLayout>
  )
}

export default TaskDetails
