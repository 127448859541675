import { FileIcon } from 'react-file-icon'
import { Download } from '@mui/icons-material'
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Checkbox,
  Box,
  IconButton,
  CircularProgress,
  styled,
} from '@mui/material'
import { TaskFile } from './types'
import { titleCase } from '../../../../helper/utils'
import useDownloadAttachment from '../../../../hooks/useDownloadAttachment'

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.teal.main,
  '&.Mui-checked': {
    color: theme.palette.teal.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.teal.light,
  },
}))

type Props = {
  taskFile: TaskFile
  isSelected: boolean
  onToggleSelect: (file: TaskFile) => void
}

const TaskFileCard = ({ taskFile, isSelected, onToggleSelect }: Props) => {
  const { handleDownloadAttachment, loading } = useDownloadAttachment()
  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <Box sx={{ width: 30, height: 30 }}>
                <FileIcon extension={taskFile.file_extension} />
              </Box>
              <Stack direction="column">
                <Typography variant="subtitle1">
                  {taskFile.file_name}
                </Typography>
                <Typography variant="caption">{taskFile.file_size}</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Download File" placement="top">
                <IconButton
                  disabled={loading}
                  onClick={() =>
                    handleDownloadAttachment(taskFile.file, taskFile.file_name)
                  }
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Download
                      sx={{
                        color: (theme) => theme.palette.teal.main,
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Select File" placement="top">
                <StyledCheckbox
                  size="medium"
                  checked={isSelected}
                  onChange={() => onToggleSelect(taskFile)}
                />
              </Tooltip>
            </Stack>
          </Stack>

          <Typography color="#929EAE">
            {taskFile.created_at} - By {titleCase(taskFile.created_by_type)}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default TaskFileCard
