import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  styled,
} from '@mui/material'
import useAddIncidentNote from './useAddIncidentNote'
import PrivateNoteForm from './PrivateNoteForm'
import CustomerNoteForm from './CustomerNoteForm'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import { useParams } from 'react-router-dom'

const TabsWrapper = styled(Tabs)(({ theme }) => ({
  marginBottom: '26px',
  '& .MuiTab-root': {
    fontSize: '1rem',
    color: '#617696',
  },

  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.teal.main,
  },
  '& .Mui-selected': {
    color: theme.palette.teal.main,
  },
}))

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const AddNoteDialog = ({ isOpen, handleClose }: Props) => {
  const { taskId } = useParams<{ taskId: string }>()
  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' = user?.user_type
  const { tabs, TABS } = useAddIncidentNote()

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle color="common.black" fontWeight="bold">
        Add Note
      </DialogTitle>
      <DialogContent>
        {userType === 'ENGINEER' && !taskId && (
          <TabsWrapper
            variant="fullWidth"
            value={tabs.value}
            onChange={tabs.onChange}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                sx={{
                  textTransform: 'none',
                }}
              />
            ))}
          </TabsWrapper>
        )}

        {tabs.value === 'private-note' && (
          <PrivateNoteForm
            handleClose={handleClose}
            resourceName={taskId ? 'TASK' : 'INCIDENT'}
          />
        )}
        {tabs.value === 'customer-note' && (
          <CustomerNoteForm handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AddNoteDialog
