import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    customText: Palette['primary']
    customCard: Palette['primary']
    teal: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    customText?: PaletteOptions['primary']
    customCard: PaletteOptions['primary']
    teal: PaletteOptions['primary']
  }
}

export const dashboardTheme = (mode: any) => {
  return createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? '#fff' : '#1E2022 !important',
            boxShadow: 'none !important',
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '42px',
            padding: '16px',
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#929EAE',
            marginBottom: '5px',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#F9F9F9',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#F2F2F2',
            paddingTop: 2,
            paddingBottom: 2,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
    },
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      primary: {
        main: '#33303B',
        dark: '#494454',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#494454',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#35B0E5',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#47A5A6',
        light: 'rgba(200, 223, 225, 0.72)',
      },
      warning: {
        main: '#F49321',
        light: 'rgba(227, 158, 0, 0.12)',
      },
      error: {
        main: '#FF6370',
      },
      background: {
        default: mode === 'light' ? '#F1F2F4' : '#121316',
      },
      customText: {
        light: mode === 'light' ? '#FFFFFF' : '#D3E3FF',
        main: '#111C2B',
        contrastText: '#111C2B',
      },
      customCard: {
        main: mode === 'light' ? '#FAF9FD !important' : '#121316 !important',
        light:
          mode === 'light' ? '#D9D9D9 !important' : 'transparent !important',
      },
      text: {
        primary: '#494454',
        secondary: '#33303B',
        disabled: '#929EAE',
      },

      teal: {
        main: '#47A5A6',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })
}
