import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { mainNavbarItems } from './consts/NavbarItems'
import { navbarStyles } from './styles'
import Collapse from '@mui/material/Collapse'
import { useNavigate } from 'react-router-dom'
import AppLogo from '../AppLogo/AppLogo'
import { Stack, Box } from '@mui/material'

// drawer conmponent;
const Navbar = () => {
    // declaring use navigate to a varibale to use it when navbar item clicked.
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState(0)
    // declaring a logical state to control collapse component
    const [open, setOpen] = React.useState(true)
    //  a logical funtion to handle toggling the collapsed area.
    const handleClick = () => {
        setOpen(!open)
    }

    return (
      <Stack direction="column" sx={{ backgroundColor: '#33303B', flexGrow: 1, minHeight: "100vh", minWidth: "15rem" }} spacing={2}>
      <Box position='fixed'>
      
      <Toolbar variant="regular">
        <Stack sx={{ marginTop: '4rem', marginLeft: '1rem'  ,position:"fixed"}}>
          <AppLogo />
        </Stack>
      </Toolbar>
      <List component="nav" sx={{position:"fixed",top:"10%"}}>
      <Divider />
        {mainNavbarItems.map((item, index) => (
          <ListItem key={item.id} disablePadding>
            {item.type === 'listItem' && (
              <ListItemButton
                color="primary"
                selected={selectedId === item.id}
                onClick={() => {
                  navigate(item.route);
                  setSelectedId(item.id);
                }}
              >
                <ListItemIcon sx={navbarStyles.icons}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={navbarStyles.text}
                  primary={item.label}
                />
              </ListItemButton>
            )}
            {item.type === 'collapseController' && (
              <ListItemButton
                color="primary"
                onClick={handleClick}
              >
                <ListItemIcon sx={navbarStyles.icons}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={navbarStyles.text}
                  primary={item.label}
                />
                {item.controller && open ? (
                  <ExpandLess sx={{ color: '#fff' }} />
                ) : (
                  <ExpandMore sx={{ color: '#fff' }} />
                )}
              </ListItemButton>
            )}
            {item.type === 'collapsedListItem' && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={navbarStyles.collapsedItem}
                    selected={selectedId === item.id}
                    onClick={() => {
                      navigate(item.route);
                      setSelectedId(item.id);
                    }}
                  >
                    <ListItemIcon sx={navbarStyles.icons}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={navbarStyles.text}
                      primary={item.label}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            )}
          </ListItem>
        ))}
          </List>
      </Box>
    </Stack>
    )
}

export default Navbar
