import {
  Box,
  Tabs,
  Tab,
  styled,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material'
import { useTabs } from '../../../hooks/useTabs'
import { IncidentHistory } from './IncidentHistory'
import { IncidentTasks } from './IncidentTasks'
import { IncidentFiles } from './IncidentFiles'
import { Add, FileDownload } from '@mui/icons-material'
import useAddIncidentTask from '../../IncidentTask/useAddIncidentTask'
import AddTaskDialog from '../../IncidentTask/AddTaskDialog'
import { useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user'
import useIncidentFiles from './IncidentFiles/useIncidentFiles'

const TabsWrapper = styled(Tabs)({
  minHeight: '34px',
  backgroundColor: '#D9E7E9',
  padding: '5px',
  borderRadius: '10px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTab-root': {
    padding: '0 60px',
    minHeight: '34px',
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: '#666',
  backgroundColor: 'transparent',

  '&.Mui-selected': {
    borderRadius: '10px',
    color: '#fff',
    backgroundColor: '#47A5A6',
  },
}))

const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.teal.main,
  borderColor: theme.palette.teal.main,
  padding: '6px 24px',
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    backgroundColor: theme.palette.teal.main,
    borderColor: theme.palette.teal.main,
    color: '#fff',
  },
}))

const TABS = [
  {
    value: 'history',
    label: 'History',
  },
  {
    value: 'tasks',
    label: 'Tasks',
  },
  {
    value: 'files',
    label: 'Files',
  },
]

type Props = {
  customerId: number
}
const IncidentTabsSection = ({ customerId }: Props) => {
  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' = user?.user_type
  const tabs = useTabs('history')

  const { isOpen, handleOpen, handleClose } = useAddIncidentTask()
  const {
    incidentFiles,
    toggleFileSelection,
    loading,
    selectedFiles,
    loadingDownloadFile,
    downloadSelectedFiles,
  } = useIncidentFiles()
  return (
    <Box width="100%" component="section">
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
        justifyContent="space-between"
        alignItems="start"
        mb={3}
      >
        <TabsWrapper
          variant="fullWidth"
          value={tabs.value}
          onChange={tabs.onChange}
        >
          {TABS.filter(
            (tab) => !(userType === 'CUSTOMER' && tab.value === 'tasks')
          ).map((tab) => (
            <StyledTab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>

        {tabs.value === 'tasks' && (
          <OutlinedButton
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              handleOpen()
            }}
          >
            Create Task
          </OutlinedButton>
        )}

        {tabs.value === 'files' && (
          <OutlinedButton
            variant="outlined"
            disabled={loadingDownloadFile || selectedFiles.length === 0}
            startIcon={
              loadingDownloadFile ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownload />
              )
            }
            onClick={() => {
              downloadSelectedFiles()
            }}
          >
            Download All
          </OutlinedButton>
        )}
      </Stack>

      {tabs.value === 'history' && <IncidentHistory />}
      {tabs.value === 'tasks' && <IncidentTasks />}
      {tabs.value === 'files' && (
        <IncidentFiles
          incidentFiles={incidentFiles}
          selectedFiles={selectedFiles}
          loading={loading}
          toggleFileSelection={toggleFileSelection}
        />
      )}

      {isOpen && (
        <AddTaskDialog
          isOpen={isOpen}
          handleClose={handleClose}
          customerId={customerId}
        />
      )}
    </Box>
  )
}

export default IncidentTabsSection
