import { Card, CardContent, Skeleton, Stack } from '@mui/material'

const IncidentTaskCardSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Skeleton variant="rectangular" width={200} height={24} />
            <Stack direction="row" spacing={2}>
              <Skeleton variant="rectangular" width={160} height={26} />
              <Skeleton variant="rectangular" width={160} height={26} />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Skeleton variant="rectangular" width={150} height={24} />
            <Skeleton variant="rectangular" width={150} height={24} />
            <Skeleton variant="rectangular" width={150} height={24} />
            <Skeleton variant="rectangular" width={150} height={24} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default IncidentTaskCardSkeleton
