import { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
import {
    customerAdminSelector,
    getCustomer,
} from '../../features/adminCustomer'
import dashBoardServices from '../../services/cspServices'
import { emptyResForLead } from '../../pages/Requests/constants'

export const useRequests = () => {
    const [newReqModalOpen, setNewReqModalOpen] = useState(false)
    const { user } = useAppSelector(userSelector)
    const [page, setPage] = useState(1)
    const [filterOpen, setFilterOpen] = useState(false)
    const [searchField, setSearchField] = useState<string>('')
    const [data, setData] = useState<any>([])
    const [filters, setFilters] = useState({
        yellow_flag: false,
        red_flag: false,
    })
    const [createdByFilter, setCreatedByFilter] = useState<string>('')
    const dispatch = useAppDispatch()
    const [filtersSelected, setFiltersSelected] = useState(false)
    const [mountRest, setMountRest] = useState(false)
    const [error, setError] = useState<any>(undefined)
    const [searchStr, setSearchStr] = useState<string | undefined>(undefined)
    const [searchFilter, setSearchFilter] = useState<string | undefined>(
        undefined
    )
    const [isPending, setIsPending] = useState(false)

    const callAPI = () => {
        setIsPending(true)
        if (user.user_type === 'ENGINEER') {
            if (user.is_leader) {
                dashBoardServices
                    .getrequestsTeamLead(
                        `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''
                        }`
                    )
                    .then(({ data }: any) => {
                        setMountRest(true)
                        if (data.count > 0) {
                            setData(filterData(data))
                        } else {
                            if (searchFilter ?? searchStr) {
                                setData(emptyResForLead)
                                const alertObj: IAlert = {
                                    id: `requests`,
                                    message: 'Not found',
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            }
                        }
                    })
                    .catch((e) => {
                        setError(e)
                        const alertObj: IAlert = {
                            id: `requests`,
                            message: 'there was an error getting requests',
                            state: true,
                            type: 'error',
                        }
                        dispatch(addAlert(alertObj))
                    })
                    .finally(() => {
                        setIsPending(false)
                    })
            } else {
                dashBoardServices
                    .getrequestsEngineer(
                        `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''
                        }`
                    )
                    .then(({ data }: any) => {
                        setMountRest(true)
                        if (data.count > 0) {
                            setData(filterData(data))
                        } else {
                            if (searchFilter ?? searchStr) {
                                setData(emptyResForLead)
                                const alertObj: IAlert = {
                                    id: `requests`,
                                    message: 'Not found',
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            }
                        }
                    })
                    .catch((e) => {
                        setError(e)
                        const alertObj: IAlert = {
                            id: `requests`,
                            message: 'there was an error getting requests',
                            state: true,
                            type: 'error',
                        }
                        dispatch(addAlert(alertObj))
                    })
                    .finally(() => {
                        setIsPending(false)
                    })
            }
        } else if (user.user_type === 'CUSTOMER') {
            user.is_admin && dispatch(getCustomer(user.customer_id))
            dashBoardServices
                .getrequestsCustomer(
                    `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''
                    }`
                )
                .then(({ data }: any) => {
                    setMountRest(true)
                    if (data.count > 0) {
                        setData(filterData(data))
                    } else {
                        if (searchFilter ?? searchStr) {
                            setData(emptyResForLead)
                            const alertObj: IAlert = {
                                id: `requests`,
                                message: 'Not found',
                                state: true,
                                type: 'error',
                            }
                            dispatch(addAlert(alertObj))
                        }
                    }
                })
                .catch((e) => {
                    setError(e)
                })
                .finally(() => {
                    setIsPending(false)
                })
        } else if (user.user_type === 'ADMIN') { 
            dashBoardServices
              .getRequestsAdmin(
                `?page=${page}&page_size=9${searchStr ?? ''}${
                  searchFilter ?? ''
                }`
              )
              .then(({ data }: any) => {
                setMountRest(true)
                if (data.count > 0) {
                  setData(filterData(data))
                } else {
                  if (searchFilter ?? searchStr) {
                    setData(emptyResForLead)
                    const alertObj: IAlert = {
                      id: `requests`,
                      message: 'Not found',
                      state: true,
                      type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                  }
                }
              })
              .catch((e) => {
                setError(e)
              })
              .finally(() => {
                setIsPending(false)
              })
        }
    }

    const filterData = (data: any) => {
        let results = data.results
        if (filters.yellow_flag) {
            results = results.filter((request: any) => request.yellow_flag)
        }
        if (filters.red_flag) {
            results = results.filter((request: any) => request.red_flag)
        }
        if (createdByFilter === 'true') {
            results = results.filter(
                (request: any) => request.created_by.id !== user.user_id
            )
        } else if (createdByFilter === 'false') {
            results = results.filter(
                (request: any) => request.created_by.id === user.user_id
            )
        }
        if (results.length === 0) {
            setData(emptyResForLead)
            if (searchFilter ?? searchStr) {
                setData(emptyResForLead)
                const alertObj: IAlert = {
                    id: `requests`,
                    message: 'Not found',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            }
        }
        return { ...data, results }
    }

    useEffect(() => {
        callAPI()
    }, [page, searchStr, searchFilter, filters])

    const handleFetch = (searchStr: string) => {
        setPage(1)
        setSearchFilter(searchStr)
        setFilterOpen(false)
    }

    const handleSearch = () => {
        setSearchStr(`&search=${searchField}`)
        setPage(1)
        setFilterOpen(false)
    }

    const handleCloseNewReq = () => {
        setNewReqModalOpen(false)
    }

    const openNewRequestModal = () => {
        setNewReqModalOpen(true)
    }

    const handleCloseFilter = () => {
        setFilterOpen(false)
    }

    const handleOpenFilter = () => {
        setFilterOpen(true)
    }

    return {
        newReqModalOpen,
        setNewReqModalOpen,
        user,
        page,
        setPage,
        filterOpen,
        setFilterOpen,
        searchField,
        setSearchField,
        data,
        filters,
        setFilters,
        createdByFilter,
        setCreatedByFilter,
        filtersSelected,
        setFiltersSelected,
        mountRest,
        error,
        searchStr,
        searchFilter,
        isPending,
        handleFetch,
        handleSearch,
        callAPI,
        handleCloseNewReq,
        openNewRequestModal,
        handleCloseFilter,
        handleOpenFilter,
    }
}
