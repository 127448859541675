import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { toast } from 'react-hot-toast'
import { Incident } from './types'
import { apiClient } from '../../services/cspServices'

const useIncidentDetails = () => {
  const { id: incidentId } = useParams<{ id: string }>()
  console.log('Incident ID:', incidentId)
  const [incident, setIncident] = useState<Incident>({
    id: '',
    subject: '',
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    customer_location: '',
    customer_organization_id: 0,
    add_note: false,
    description: '',
    status: '',
    total_effort_hours: '',
    priority: '',
    assigned_to: '',
    impact: '',
    escalation_status: '',
    bug_related: '',
    sla_response_time: '',
    opened_at: '',
    updated_at: '',
    response_time: '',
    resolved_time: '',
    category: {
      id: 0,
      skill: {
        id: 0,
        skill: '',
      },
      customer_product: {
        id: 0,
        product: {
          id: 0,
          name: '',
        },
        release: '',
        paltform: '',
        follow_sla: false,
        site: {
          id: 0,
          name: '',
        },
      },
    },
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<Incident> = await apiClient.get(
          `/v2/service/request/${incidentId}`
        )
        console.log('Incident details:', res.data)
        setIncident(res.data)
      } catch (error) {
        console.error('Error fetching incident details:', error)
        toast.error('Error fetching incident details')
      } finally {
        setLoading(false)
      }
    }
    fetchIncidentDetails()
  }, [incidentId])

  return {
    incident,
    loading,
  }
}

export default useIncidentDetails
