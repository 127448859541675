import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectUserReducer } from '../../../features/user'

import { addAlert } from '../../../features/systemAlerts'

import dashBoardServices from '../../../services/cspServices'

import { FormDataSchema, type FormData } from './FormData'
import { AttachmentFile } from './types'

export const useNewIncidentForm = (attachmentFiles: AttachmentFile[]) => {
  const { user } = useAppSelector(selectUserReducer)

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { full_name: fullName, email, phone } = user

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(FormDataSchema),
    defaultValues: {
      customerName: fullName || '',
      email: email || '',
      phone: phone || '',
      title: '',
      category: '',
      subCategory: '',
      priority: '',
      impact: '',
      description: '',
      attachments: [],
    },
  })

  const selectedCategoryId = watch('category')

  const onSubmit = async (data: FormData) => {
    setLoading(true)
    try {
      const request = {
        title: data.title,
        impact: data.impact,
        category: +data.category,
        sub_category: +data.subCategory,
        priority: +data.priority,
        attachments: attachmentFiles.map((file) => file.id),
        description: data.description,
        phone_number: data.phone,
      }
      const {
        data: { id },
      } = await dashBoardServices.createNewRequest(request)
      toast.success('Incident created successfully')
      navigate(`/incidents/${id}`)
    } catch (error) {
      console.error('Error submitting request:', error)
      dispatch(
        addAlert({
          id: 'submit-error',
          message: 'Failed to submit request',
          state: true,
          type: 'error',
        })
      )
    } finally {
      setLoading(false)
    }
  }

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    setValue,
    loading,
    selectedCategoryId,
    reset,
  }
}
