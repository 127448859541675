import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
  InputLabel,
  FormGroup,
  Button,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import toast from 'react-hot-toast'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { useFileAttachments } from '../Modals/NewRequestModal/useFileAttachments'
import AttachmentSection from '../Modals/NewRequestModal/AttachmentSection'
import { Engineer, Team } from './types'
import dashBoardServices, { apiClient } from '../../services/cspServices'
import { incidentImpactItems } from '../Modals/NewRequestModal/RequestForm'
import { AxiosResponse } from 'axios'
import { Priority } from '../Modals/NewRequestModal/types'

const schema = z.object({
  title: z
    .string()
    .min(1, 'Title is required')
    .max(60, 'Title must be less than 60 characters'),
  team: z.string().min(1, 'Team is required'),
  engineer: z.string().min(1, 'Engineer is required'),
  priority: z.string().min(1, 'Priority is required'),
  impact: z.string().min(1, 'Impact is required'),
  description: z.string(),
})

type FormData = z.infer<typeof schema>

type Props = {
  isOpen: boolean
  customerId: number
  handleClose: () => void
}

const AddTaskDialog = ({ isOpen, customerId, handleClose }: Props) => {
  const [teams, setTeams] = useState<Team[]>([])
  const [engineers, setEngineers] = useState<Engineer[]>([])
  const [priorities, setPriorities] = useState<Priority[]>([])

  const { id: incidentId } = useParams<{ id: string }>()

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      title: '',
      team: '',
      engineer: '',
      priority: '',
      impact: '',
      description: '',
    },
    resolver: zodResolver(schema),
  })

  const selectedTeam = watch('team')

  const {
    fileLoading,
    attachmentFiles,
    deleteFileAttachment,
    handleFileUpload,
  } = useFileAttachments('TASK')

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const res = await apiClient.post(`v2/repo/request/${incidentId}/task/`, {
        ...data,
        team: +data.team,
        priority: +data.priority,
        engineer: +data.engineer,
        attachments: attachmentFiles.map((file) => file.id),
      })
      console.log('Task created:', res.data)
      toast.success('Task created successfully')
      handleClose()
      // TODO: call the API that fetch tasks instead
      // reload to fetch data from the backend
      window.location.reload()
    } catch (error) {
      console.error('Error creating task:', error)
      toast.error('Something went wrong while creating the task')
    }
  }

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const res: AxiosResponse<Team[]> = await apiClient.get(
          'v1/query/teams/'
        )
        setTeams(res.data)
      } catch (error) {
        console.error('Error fetching teams:', error)
      }
    }

    fetchTeams()
  }, [])

  useEffect(() => {
    const fetchPriorityData = async () => {
      try {
        const response = await dashBoardServices.getCustomerPriorities(
          customerId
        )

        setPriorities(response.data[0].priorities)
      } catch (error) {
        console.error('Error fetching priorities data:', error)
      }
    }
    fetchPriorityData()
  }, [customerId])

  useEffect(() => {
    if (selectedTeam) {
      const engineers = teams.find((team) => team.id === +selectedTeam)
        ?.engineers as Engineer[]

      setEngineers(engineers)
      setValue('engineer', '')
    } else {
      setEngineers([])
      setValue('engineer', '')
    }
  }, [selectedTeam])

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle color="common.black" fontWeight="bold" mb={3}>
        Create New Task
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="title" error={!!errors.title}>
                    Title
                  </InputLabel>
                  <TextField
                    id="title"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!errors.title}
                    helperText={!!errors.title && errors.title.message}
                  />
                </FormGroup>
              )}
            />

            <Controller
              name="team"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="team" error={!!errors.team}>
                    Team
                  </InputLabel>
                  <TextField
                    id="team"
                    fullWidth
                    select
                    size="small"
                    {...field}
                    error={!!errors.team}
                    helperText={!!errors.team && errors.team.message}
                  >
                    {teams.length === 0 && (
                      <MenuItem value="" disabled>
                        No teams available
                      </MenuItem>
                    )}
                    {teams.map((team) => (
                      <MenuItem key={team.id} value={team.id.toString()}>
                        {team.team_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              )}
            />

            <Controller
              name="engineer"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="engineer" error={!!errors.engineer}>
                    Engineer
                  </InputLabel>
                  <TextField
                    id="engineer"
                    fullWidth
                    select
                    size="small"
                    {...field}
                    error={!!errors.engineer}
                    helperText={!!errors.engineer && errors.engineer.message}
                    disabled={!selectedTeam}
                  >
                    {engineers.map((engineer) => (
                      <MenuItem
                        key={engineer.id}
                        value={engineer.id.toString()}
                      >
                        {engineer.full_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              )}
            />

            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="priority" error={!!errors.priority}>
                    Priority
                  </InputLabel>
                  <TextField
                    id="priority"
                    fullWidth
                    size="small"
                    {...field}
                    select
                    error={!!errors.priority}
                    helperText={!!errors.priority && errors.priority.message}
                  >
                    {priorities.map((priority) => (
                      <MenuItem
                        key={priority.id}
                        value={priority.id.toString()}
                      >
                        {priority.priority_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              )}
            />

            <Controller
              name="impact"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="impact" error={!!errors.impact}>
                    Impact
                  </InputLabel>
                  <TextField
                    id="impact"
                    fullWidth
                    size="small"
                    {...field}
                    select
                    error={!!errors.impact}
                    helperText={!!errors.impact && errors.impact.message}
                  >
                    {incidentImpactItems.map((impact) => (
                      <MenuItem key={impact.key} value={impact.key}>
                        {impact.impactLabel}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel
                    htmlFor="description"
                    error={!!errors.description}
                  >
                    Description
                  </InputLabel>
                  <TextField
                    id="description"
                    fullWidth
                    size="small"
                    {...field}
                    error={!!errors.description}
                    helperText={
                      !!errors.description && errors.description.message
                    }
                    multiline
                    minRows={4}
                  />
                </FormGroup>
              )}
            />
          </Stack>

          <AttachmentSection
            attachmentFiles={attachmentFiles}
            handleDeleteRequest={deleteFileAttachment}
            handleFileUpload={handleFileUpload}
            fileLoading={fileLoading}
          />

          <DialogActions
            sx={{
              mt: 4,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddTaskDialog
