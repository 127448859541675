import React, { useState } from 'react'
import { Button, Typography, Stack } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Variant } from '@mui/material/styles/createTypography'

interface ExpandableTextProps {
  text: string
  maxChars?: number
  variant?: Variant | 'inherit'
  showToggleButton?: boolean
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxChars = 250,
  variant = 'body1',
  showToggleButton = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const displayText = isExpanded ? text : text.slice(0, maxChars)

  return (
    <Stack>
      <Typography
        variant={variant}
        title={variant.includes('h') && text.length > maxChars ? text : ''}
      >
        {displayText}
        {text.length > maxChars && !isExpanded && '...'}
      </Typography>
      {text.length > maxChars && showToggleButton && (
        <Button
          variant="text"
          onClick={toggleExpanded}
          color="primary"
          size="large"
          sx={{
            fontWeight: 'bold',
            alignSelf: 'flex-end',
            color: (theme) => theme.palette.teal.main,
          }}
          endIcon={
            isExpanded ? (
              <ExpandLessIcon color="inherit" />
            ) : (
              <ExpandMoreIcon color="inherit" />
            )
          }
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Stack>
  )
}

export default ExpandableText
