import { Stack, Typography } from '@mui/material'
import { ReactComponent as NoRepliesIcon } from '../../assets/images/Noreplies.svg'

const NoReplies = () => (
    <Stack alignItems="center" spacing={2}>
        <NoRepliesIcon />
        <Typography variant="h6" fontWeight="bold">
            No Replies until now
        </Typography>
    </Stack>
)

export default NoReplies
