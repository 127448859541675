import { Stack, Alert } from '@mui/material'
import IncidentFileCard from './IncidentFileCard'
import IncidentTaskCardSkeleton from '../IncidentTasks/IncidentTaskCardSkeleton'
import { IncidentFile } from './types'

type Props = {
  incidentFiles: IncidentFile[]
  selectedFiles: IncidentFile[]
  loading: boolean
  toggleFileSelection: (file: IncidentFile) => void
}

const IncidentFiles = ({
  incidentFiles,
  selectedFiles,
  loading,
  toggleFileSelection,
}: Props) => {
  if (!incidentFiles.length && !loading) {
    return (
      <Alert
        variant="filled"
        severity="error"
        sx={{
          color: 'common.white',
        }}
      >
        There are no files for this incident
      </Alert>
    )
  }
  return (
    <Stack direction="column" spacing={3}>
      {loading
        ? [...Array(5)].map((key) => <IncidentTaskCardSkeleton key={key} />)
        : incidentFiles.map((file) => (
            <IncidentFileCard
              key={file.id}
              incidentFile={file}
              isSelected={selectedFiles.some((f) => f.id === file.id)}
              onToggleSelect={toggleFileSelection}
            />
          ))}
    </Stack>
  )
}

export default IncidentFiles
