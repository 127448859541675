import { useState } from 'react'

const useAddIncidentTask = () => {
  const [openAddTaskDialog, setAddTaskDialog] = useState(false)

  const handleOpen = () => setAddTaskDialog(true)
  const handleClose = () => setAddTaskDialog(false)

  return {
    isOpen: openAddTaskDialog,
    handleOpen,
    handleClose,
  }
}

export default useAddIncidentTask
