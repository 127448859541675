import { useEffect, useState } from 'react'
import {
  Typography,
  Stack,
  styled,
  Menu,
  MenuItem,
  Button,
} from '@mui/material'

import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'

import { titleCase } from '../../helper/utils'
import { ArrowDropDown } from '@mui/icons-material'
import dashBoardServices, { apiClient } from '../../services/cspServices'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Priority } from '../Modals/NewRequestModal/types'

const IncidentPriorityWrapper = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#E6F4F1',
  padding: '8px',
  borderRadius: '4px',
})

type Props = {
  currentPriority: string
  customerId: number
  resourceName?: 'INCIDENT' | 'TASK'
}

type PriorityOption = {
  key: string
  label: string
}

const IncidentPriorityCard = ({
  currentPriority,
  customerId,
  resourceName = 'INCIDENT',
}: Props) => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()

  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' | 'ADMIN' = user?.user_type

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [priorityStatus, setPriorityStatus] = useState(currentPriority)
  const [incidentPriorities, SetIncidentPriorities] = useState<Priority[]>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchIncidentPriorityOptions = async () => {
      setLoading(true)

      try {
        const response = await dashBoardServices.getCustomerPriorities(
          customerId
        )

        SetIncidentPriorities(response.data[0].priorities)
      } catch (error) {
        console.error('Error fetching priorities data:', error)
        toast.error('Error fetching Priority options')
      } finally {
        setLoading(false)
      }
    }
    if (resourceName === 'TASK') {
      fetchIncidentPriorityOptions()
    } else if (resourceName === 'INCIDENT') {
      fetchIncidentPriorityOptions()
    }
  }, [resourceName, customerId])

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePriority = async (
    newPriority: PriorityOption | Priority
  ) => {
    setLoading(true)
    try {
      const url =
        resourceName === 'INCIDENT'
          ? `/v2/service/request/${incidentId}/update/`
          : `/v2/service/request/${incidentId}/task/${taskId}/update/`
      const res = await apiClient.patch(url, {
        priority:
          resourceName === 'INCIDENT'
            ? (newPriority as Priority).id
            : (newPriority as Priority).id,
      })
      console.log('Incident priority updated:', res.data)
      setPriorityStatus(
        resourceName === 'INCIDENT'
          ? (newPriority as Priority).priority_name
          : (newPriority as Priority).priority_name
      )
      toast.success(`${titleCase(resourceName)} priority updated successfully`)
      handleClose()
    } catch (error) {
      console.error('Error updating priority:', error)
      toast.error(`Error updating ${titleCase(resourceName)} priority`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <IncidentPriorityWrapper>
      <Typography color="common.black">Priority</Typography>

      {userType === 'CUSTOMER' && (
        <Typography
          sx={{
            backgroundColor: '#47A5A61A',
            color: '#47A5A6',
            padding: '4px 12px',
            borderRadius: '3px',
          }}
        >
          {titleCase(priorityStatus)}
        </Typography>
      )}

      {(userType === 'ENGINEER' || userType === 'ADMIN') && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            disabled={loading}
          >
            {titleCase(priorityStatus)}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {resourceName === 'INCIDENT'
              ? incidentPriorities.map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={() => handleChangePriority(option)}
                    selected={option.priority_name === priorityStatus}
                    disabled={option.priority_name === priorityStatus}
                  >
                    {titleCase(option.priority_name)}
                  </MenuItem>
                ))
              : incidentPriorities.map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={() => handleChangePriority(option)}
                    selected={option.priority_name === priorityStatus}
                    disabled={option.priority_name === priorityStatus}
                  >
                    {titleCase(option.priority_name)}
                  </MenuItem>
                ))}
          </Menu>
        </>
      )}
    </IncidentPriorityWrapper>
  )
}

export default IncidentPriorityCard