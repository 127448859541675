import { useState } from 'react'
import baseURL from '../services/baseUrl'
import { apiClient } from '../services/cspServices'
import toast from 'react-hot-toast'

const useDownloadAttachment = () => {
  const [loading, setLoading] = useState(false)
  const handleDownloadAttachment = async (
    filePath: string,
    fileName: string
  ) => {
    try {
      setLoading(true)
      const fileURL = baseURL().globalUrl + filePath
      const res = await apiClient.get(fileURL, { responseType: 'blob' })
      console.log('res ', res)
      setLoading(false)
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
      toast.error('Error downloading file')
      setLoading(false)
    }
  }

  return {
    handleDownloadAttachment,
    loading,
  }
}

export default useDownloadAttachment
