import React from 'react'
import {
    Stack,
    Button,
    CircularProgress,
    InputLabel,
    Box,
    styled,
} from '@mui/material'
import { ReactComponent as Add } from '../../../assets/images/iconAdd.svg'

import type { AttachmentFile as Attachment } from './types'
import EnhancedAttachmentFile from './EnhancedAttachmentFile'

const AttachmentFilesContainer = styled(Stack)({
    gap: 1,
    maxWidth: 'fit-content',
    minWidth: '100%',
    minHeight: '50px',
    borderRadius: '4px',
    border: '1px solid #F2F2F2',
    background: '#F9F9F9',
    padding: '9px 12px 12px 9px',
    flexWrap: 'wrap',
})

type AttachmentSectionProps = {
    attachmentFiles: Attachment[]
    handleDeleteRequest: (id: number) => void
    handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
    fileLoading: boolean
}
const AttachmentSection: React.FC<AttachmentSectionProps> = ({
    attachmentFiles,
    handleDeleteRequest,
    handleFileUpload,
    fileLoading,
}) => {
    return (
        <Box mt={2}>
            <InputLabel>Attachments</InputLabel>
            <AttachmentFilesContainer direction="row">
                {attachmentFiles.map((item) => (
                    <EnhancedAttachmentFile
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        deleteFile={handleDeleteRequest}
                    />
                ))}
                <Stack
                    alignSelf="flex-end"
                    sx={{
                        ml: 'auto',
                    }}
                >
                    <label htmlFor="upload-image">
                        <Button
                            component="span"
                            variant="outlined"
                            startIcon={
                                fileLoading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Add />
                                )
                            }
                        >
                            {fileLoading ? 'Loading' : 'Add'}
                        </Button>
                        <input
                            id="upload-image"
                            hidden
                            accept="image/*,.pdf,.doc,.docx"
                            type="file"
                            multiple
                            onChange={handleFileUpload}
                        />
                    </label>
                </Stack>
            </AttachmentFilesContainer>
        </Box>
    )
}

export default AttachmentSection
