import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
  InputLabel,
  FormGroup,
  Button,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import toast from 'react-hot-toast'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Engineer } from '../IncidentTask/types'
import { apiClient } from '../../services/cspServices'
import { titleCase } from '../../helper/utils'

const schema = z.object({
  engineer: z.string().min(1, { message: 'Please select an engineer' }),
})

type FormData = z.infer<typeof schema>

type Props = {
  isOpen: boolean
  handleClose: () => void
  resourceName?: 'INCIDENT' | 'TASK'
  currentAssignedEngineer: string
}

const ReassignDialog = ({
  isOpen,
  handleClose,
  currentAssignedEngineer,
  resourceName = 'INCIDENT',
}: Props) => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()
  const [engineers, setEngineers] = useState<Engineer[]>([])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      engineer: '',
    },
    resolver: zodResolver(schema),
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const url =
        resourceName === 'INCIDENT'
          ? `/v2/service/request/${incidentId}/update/`
          : `/v2/service/request/${incidentId}/task/${taskId}/update/`

      const res = await apiClient.patch(url, { assigned_to: +data.engineer })
      console.log(' reassigned:', res.data)
      toast.success(`${titleCase(resourceName)} reassigned successfully`)
      reset()
      window.location.reload()

      handleClose()
    } catch (error) {
      console.error('Error reassigned:', error)
      toast.error(`Error in reassigning ${titleCase(resourceName)}`)
    }
  }

  useEffect(() => {
    const fetchEngineers = async () => {
      try {
        const res = await apiClient.get('v2/query/users')
        console.log('Engineers:', res.data)
        const filteredEngineers = res.data.filter(
          (engineer: Engineer) => engineer.full_name !== currentAssignedEngineer
        )
        setEngineers(filteredEngineers)
      } catch (error) {
        console.error('Error fetching engineers:', error)
      }
    }

    fetchEngineers()
  }, [currentAssignedEngineer])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={() => {
        reset()
        handleClose()
      }}
    >
      <DialogTitle color="common.black" fontWeight="bold">
        Reassign {titleCase(resourceName)}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            <Controller
              name="engineer"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <InputLabel htmlFor="engineer" error={!!errors.engineer}>
                    Engineer
                  </InputLabel>
                  <TextField
                    id="engineer"
                    fullWidth
                    select
                    size="small"
                    placeholder="Select Engineer"
                    {...field}
                    error={!!errors.engineer}
                    helperText={!!errors.engineer && errors.engineer.message}
                  >
                    {engineers.map((engineer) => (
                      <MenuItem
                        key={engineer.id}
                        value={engineer.id.toString()}
                      >
                        {engineer.full_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              )}
            />
          </Stack>

          <DialogActions
            sx={{
              mt: 4,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => {
                reset()
                handleClose()
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ReassignDialog
