import { Stack, CircularProgress } from '@mui/material'

const LoadingSpinner = () => (
    <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ minHeight: '80vh' }}
    >
        <CircularProgress color="inherit" size={50} />
    </Stack>
)

export default LoadingSpinner
