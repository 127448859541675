import { Stack, Alert } from '@mui/material'
import IncidentTaskCard from './IncidentTaskCard'
import IncidentTaskCardSkeleton from './IncidentTaskCardSkeleton'
import useIncidentTasks from './useIncidentTasks'

const IncidentTasks = () => {
  const { incidentTasks, loading } = useIncidentTasks()
  if (!incidentTasks.length && !loading) {
    return (
      <Alert
        variant="filled"
        severity="error"
        sx={{
          color: 'common.white',
        }}
      >
        There are no tasks for this incident
      </Alert>
    )
  }
  return (
    <Stack direction="column" spacing={2}>
      {loading
        ? [...Array(5)].map((key) => <IncidentTaskCardSkeleton key={key} />)
        : incidentTasks.map((task) => (
            <IncidentTaskCard key={task.id} task={task} />
          ))}
    </Stack>
  )
}

export default IncidentTasks
