import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  TextField,
  FormGroup,
  InputLabel,
  DialogActions,
  Button,
  Stack,
  Autocomplete,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import dayjs, { type Dayjs } from 'dayjs'
import toast from 'react-hot-toast'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { LoadingButton } from '@mui/lab'
import { useFileAttachments } from '../Modals/NewRequestModal/useFileAttachments'
import AttachmentSection from '../Modals/NewRequestModal/AttachmentSection'
import { UserData } from './types'
import UserProfileItem from './UserProfileItem'
import { apiClient } from '../../services/cspServices'
import axios from 'axios'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'

const engineerSchema = z.object({
  to: z
    .array(
      z.object({
        id: z.number(),
        full_name: z.string(),
        email: z.string().email(),
      })
    )
    .optional(),
  sendCustomerEmail: z.boolean().default(false),
  description: z.string().min(1, 'Description is required'),
  effort: z
    .instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Please enter a valid time in this format HH:mm',
    })
    .refine((val) => val.isValid(), {
      message: 'Please enter a valid time in this format HH:mm',
    }),
})

const customerSchema = z.object({
  description: z.string().min(1, 'Description is required'),
})

type FormData = {
  to: { id: number; full_name: string; email: string }[]
  sendCustomerEmail: boolean
  description: string
  effort?: Dayjs
}

type Props = {
  handleClose: () => void
  resourceName: 'INCIDENT' | 'TASK'
}

const PrivateNoteForm = ({ handleClose, resourceName = 'INCIDENT' }: Props) => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()
  const { user } = useAppSelector(userSelector)

  const userType: 'CUSTOMER' | 'ENGINEER' = user?.user_type

  const schema = userType === 'ENGINEER' ? engineerSchema : customerSchema

  const [engineers, setEngineers] = useState<UserData[]>([])

  useEffect(() => {
    // Will return engineers only
    const fetchEngineers = async () => {
      try {
        const res = await apiClient.get('v2/query/users')
        console.log('Engineers:', res.data)
        setEngineers(res.data)
      } catch (error) {
        console.error('Error fetching engineers:', error)
      }
    }

    fetchEngineers()
  }, [])

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      to: [],
      sendCustomerEmail: false,
      description: '',
      effort: undefined,
    },
    resolver: zodResolver(schema),
  })

  const currentTO = watch('to')

  const disableSendEmail = !currentTO?.length

  useEffect(() => {
    if (currentTO?.length === 0) {
      setValue('sendCustomerEmail', false)
    }
  }, [currentTO, setValue])

  const {
    fileLoading,
    attachmentFiles,
    deleteFileAttachment,
    handleFileUpload,
  } = useFileAttachments('NOTE')
  const mapFormDataToRequestPayload = (data: FormData) => ({
    ...data,
    to: userType === 'ENGINEER' ? data.to?.map((user) => user.id) : undefined,
    effort:
      userType === 'ENGINEER' && data.effort
        ? data.effort.format('HH:mm')
        : undefined,
    attachments: attachmentFiles.map((file) => file.id),
    email_sent: userType === 'ENGINEER' ? data.sendCustomerEmail : undefined,
    internal: true,
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = mapFormDataToRequestPayload(data)
    console.log('Payload:', payload)
    try {
      let createTaskNoteUrl = ''

      if (resourceName === 'INCIDENT') {
        createTaskNoteUrl =
          userType === 'ENGINEER'
            ? `v2/service/request/${incidentId}/create-note/`
            : `v2/service/request/${incidentId}/customer-create-note/`
      } else if (resourceName === 'TASK') {
        createTaskNoteUrl = `v2/service/request/${incidentId}/task/${taskId}/create-note/`
      }

      const res = await apiClient.post(createTaskNoteUrl, payload)

      console.log('response:', res.data)

      toast.success('Note created successfully')
      reset()
      handleClose()
      // TODO: call the API that fetch history instead
      // reload to fetch data from the backend
      window.location.reload()
    } catch (error) {
      console.error('Error creating note:', error)
      toast.error('Something went wrong while creating the note')

      if (axios.isAxiosError(error)) {
        const resError = error.response
        resError?.status === 403 &&
          toast.error(
            resError?.data?.detail ?? 'You are not authorized to upload files'
          )
      } else {
        toast.error('Something went wrong while creating the note')
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={1}>
        {userType === 'ENGINEER' && (
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="to" error={!!errors.to}>
                  To
                </InputLabel>
                <Autocomplete
                  id="to"
                  {...field}
                  multiple
                  options={engineers}
                  getOptionLabel={(option) =>
                    `${option.full_name} (${option.email})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => {
                    field.onChange(value)
                  }}
                  renderTags={(value: UserData[], getTagProps) =>
                    value.map((option: UserData, index: number) => (
                      <Chip
                        variant="outlined"
                        label={`${option.email}`}
                        {...getTagProps({ index })}
                        key={option.id}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <UserProfileItem
                      name={option.full_name}
                      email={option.email}
                      props={props}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!errors.to}
                      helperText={errors.to ? errors.to.message : ''}
                    />
                  )}
                />
              </FormGroup>
            )}
          />
        )}

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <InputLabel htmlFor="description" error={!!errors.description}>
                Description
              </InputLabel>

              <TextField
                {...field}
                id="description"
                fullWidth
                size="small"
                multiline
                rows={8}
                error={!!errors.description}
                helperText={!!errors.description && errors.description.message}
              />
            </FormGroup>
          )}
        />

        {userType === 'ENGINEER' && (
          <Controller
            name="effort"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <InputLabel htmlFor="effort" error={!!errors.effort}>
                  Effort
                </InputLabel>

                <TimeField
                  {...field}
                  id="effort"
                  format="HH:mm"
                  fullWidth
                  size="small"
                  inputProps={{
                    placeholder: 'HH:mm',
                  }}
                  slotProps={{
                    textField: {
                      error: !!errors.effort,
                      helperText: !!errors.effort && errors.effort.message,
                    },
                  }}
                />
              </FormGroup>
            )}
          />
        )}
      </Stack>

      <AttachmentSection
        attachmentFiles={attachmentFiles}
        handleDeleteRequest={deleteFileAttachment}
        handleFileUpload={handleFileUpload}
        fileLoading={fileLoading}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mt: 4,
        }}
      >
        {userType === 'ENGINEER' && (
          <Controller
            name="sendCustomerEmail"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="success"
                      disabled={disableSendEmail}
                      {...field}
                      checked={field.value}
                    />
                  }
                  label="Send email"
                />
              </FormGroup>
            )}
          />
        )}

        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            loading={isSubmitting}
            disabled={isSubmitting || fileLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Stack>
    </form>
  )
}

export default PrivateNoteForm
