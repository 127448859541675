import { Stack, Typography } from '@mui/material'
import CustomerProfileImage from './CustomerProfileImage'

type Props = {
    userType: string
}

const RequestsPageHeader = ({ userType }: Props) => (
    <Stack direction={'row'} spacing={2} sx={{ paddingLeft: 4 }}>
        {userType == 'CUSTOMER' && <CustomerProfileImage />}
        <Typography variant={'h6'} sx={{ color: '#33303B' }}>
            Your Requests
        </Typography>
    </Stack>
)

export default RequestsPageHeader
