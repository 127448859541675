import { z } from 'zod'



export const FormDataSchema = z.object({
    customerName: z.string().min(1, 'Customer name is required'),
    email: z.string().min(1, 'Customer email is required').email(),
    phone: z.string().optional(),
    title: z.string().min(1, 'Title is required').max(60, 'Title must be less than 60 characters'),
    category: z.string().min(1, 'Category is required'),
    subCategory: z.string().min(1, 'Related To is required'),
    priority: z.string().min(1, 'Priority is required'),
    impact: z.string().min(1, 'Impact is required'),
    description: z.string().min(1, 'Description is required'),
    attachments: z.array(z.instanceof(File)),
})

export type FormData = z.infer<typeof FormDataSchema>
