import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../../services/cspServices'
import { TaskFile } from './types'
import { AxiosResponse } from 'axios'
import baseURL from '../../../../services/baseUrl'

const useTasksFiles = () => {
  const { id: incidentId, taskId } = useParams<{ id: string; taskId: string }>()

  const [taskFiles, setTaskFiles] = useState<TaskFile[]>([])

  const [loading, setLoading] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState<TaskFile[]>([])

  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false)

  const toggleFileSelection = (file: TaskFile) => {
    setSelectedFiles((prev) =>
      prev.some((f) => f.id === file.id)
        ? prev.filter((f) => f.id !== file.id)
        : [...prev, file]
    )
  }

  const downloadFile = async (file: TaskFile) => {
    const filePath = file.file
    const fileName = file.file_name
    try {
      setLoadingDownloadFile(true)
      const fileURL = baseURL().globalUrl + filePath
      const res = await apiClient.get(fileURL, { responseType: 'blob' })
      console.log('res ', res)
      setLoadingDownloadFile(false)
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
      toast.error('Error downloading file')
      setLoadingDownloadFile(false)
    }
  }

  const downloadSelectedFiles = () => {
    selectedFiles.forEach((file) => downloadFile(file))
    setSelectedFiles([])
  }

  useEffect(() => {
    const fetchTaskFiles = async () => {
      setLoading(true)
      try {
        const res: AxiosResponse<TaskFile[]> = await apiClient.get(
          `/v2/repo/request/${incidentId}/task/${taskId}/files/`
        )
        console.log('task files:', res.data)
        setTaskFiles(res.data)
      } catch (error) {
        console.error('Error fetching task files:', error)
        toast.error('Error fetching task files')
      } finally {
        setLoading(false)
      }
    }
    fetchTaskFiles()
  }, [incidentId, taskId])

  return {
    taskFiles,
    toggleFileSelection,
    loading,
    selectedFiles,
    loadingDownloadFile,
    downloadSelectedFiles,
  }
}

export default useTasksFiles
