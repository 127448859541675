import { useState } from 'react'

import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  FormGroup,
  FormLabel,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import toast from 'react-hot-toast'

import BasicImageContainer from '../../components/BasicImageContainer/BasicImageContainer'
import ITRanks from '../../assets/images/ITRanks.svg'
import ImageLogin from '../../assets/images/login.png'
import dashBoardServices from '../../services/cspServices'

// Validation Schema
const passwordSchema = z
  .object({
    newPassword: z
      .string()
      .min(6, 'New password must be at least 6 characters')
      .regex(/[a-z]/, 'Must contain at least one lowercase letter')
      .regex(/[A-Z]/, 'Must contain at least one uppercase letter')
      .regex(/\d/, 'Must contain at least one number')
      .regex(
        /[@$!%*?&]/,
        'Must contain at least one special character (@$!%*?&)'
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

type PasswordFormData = z.infer<typeof passwordSchema>

const ChangePassword = () => {
  const navigate = useNavigate()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmedNewPassword, setShowConfirmedNewPassword] =
    useState(false)

  const { data, data2 } = useParams()
  console.log('data', data)
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<PasswordFormData>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: zodResolver(passwordSchema),
    mode: 'all',
  })

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const onSubmit: SubmitHandler<PasswordFormData> = (form) => {
    if (data && data2)
      dashBoardServices
        .resetForgetPassword(
          {
            new_password1: form.newPassword,
            new_password2: form.confirmPassword,
            uid: data,
            token: data2,
          },
          data,
          data2
        )
        .then((res) => {
          console.log('res', res)
          toast.success('Password is rest successfully')
          navigate(`/`)
        })
        .catch((e) => {
          console.error('ERROR', e)
          toast.error('Something went wrong while resting the password!')
        })
        .finally(() => {})
  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={6}
          sx={{
            background: 'linear-gradient(180deg, #464153 0%, #282432 100%);',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack
            direction={'column'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            width="100%"
          >
            <Stack direction={'row'}>
              <BasicImageContainer
                sx={{
                  width: '100%',
                }}
                src={ITRanks}
                alt=""
                style={{
                  height: '100%',
                  width: '100%',
                }}
                key={'imagLogin'}
              />
            </Stack>
            <Typography variant="h5" my={6} color="common.white">
              New Password
            </Typography>
            <Box width="40%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} spacing={2} width="100%">
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                      <FormGroup>
                        <FormLabel
                          htmlFor="newPassword"
                          error={!!errors.newPassword}
                        >
                          Set new password
                        </FormLabel>

                        <TextField
                          id="newPassword"
                          hiddenLabel
                          fullWidth
                          size="small"
                          type={showNewPassword ? 'text' : 'password'}
                          {...field}
                          error={!!errors.newPassword}
                          helperText={errors.newPassword?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <RemoveRedEyeIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormGroup>
                    )}
                  />

                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <FormGroup>
                        <FormLabel
                          htmlFor="confirmPassword"
                          error={!!errors.confirmPassword}
                        >
                          Confirm new password
                        </FormLabel>
                        <TextField
                          id="confirmPassword"
                          fullWidth
                          size="small"
                          type={showConfirmedNewPassword ? 'text' : 'password'}
                          {...field}
                          error={!!errors.confirmPassword}
                          helperText={errors.confirmPassword?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmedNewPassword(
                                      !showConfirmedNewPassword
                                    )
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmedNewPassword ? (
                                    <RemoveRedEyeIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormGroup>
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: (theme) => theme.palette.teal.main,
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.teal.dark,
                      },
                    }}
                    fullWidth
                  >
                    Reset
                  </Button>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <BasicImageContainer
            sx={{
              height: '100vh',
            }}
            src={ImageLogin}
            alt=""
            style={{
              width: '100%',
              height: '100%',
            }}
            key={'imagLogin'}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ChangePassword
