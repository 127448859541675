import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon'
import { Stack, Typography, IconButton, Tooltip, styled } from '@mui/material'
import { ReactComponent as Close } from '../../../assets/images/x.svg'
import { getFileExtension } from '../../../helper/utils'

// -----------------------------------------------------------------------------

const AttachedFileContainer = styled(Stack)({
    borderRadius: 2,
    border: '1px solid #F2F2F2',
    background: '#FFF',
    padding: '2px',
})

const FileIconWrapper = styled(Stack)({
    width: 30,
    height: 30,
})

// -----------------------------------------------------------------------------

type Props = {
    id: number
    name: string
    deleteFile: (id: number) => void
}

// -----------------------------------------------------------------------------

const EnhancedAttachmentFile = ({ id, name, deleteFile }: Props) => {
    const fileExtension = getFileExtension(name)
    return (
        <AttachedFileContainer
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                <FileIconWrapper>
                    <FileIcon
                        extension={fileExtension}
                        {...defaultStyles[
                            fileExtension as DefaultExtensionType
                        ]}
                    />
                </FileIconWrapper>
                <Typography variant="caption">{name}</Typography>
            </Stack>
            <Tooltip title="Remove" placement="top">
                <IconButton
                    color="error"
                    disableRipple
                    onClick={() => {
                        deleteFile(id)
                    }}
                >
                    <Close />
                </IconButton>
            </Tooltip>
        </AttachedFileContainer>
    )
}

export default EnhancedAttachmentFile
