import { TableRow, TableCell, Skeleton } from '@mui/material'

type Props = {
  numberOfRows: number
  numberOfCells: number
}

const TableRowsLoader = ({ numberOfRows, numberOfCells }: Props) => {
  return [...Array(numberOfRows)].map((_, index) => (
    <TableRow key={index}>
      {[...Array(numberOfCells)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ))
}

export default TableRowsLoader
