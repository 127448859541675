import { useState, useEffect, useCallback } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
    type SxProps,
    useMediaQuery,
    CircularProgress,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import type { IAlert } from '../../../interfaces/IAlert'

import { useForm, Controller, useWatch } from 'react-hook-form'
import { ReactComponent as Add } from '../../../assets/images/iconAdd.svg'
import { ReactComponent as Pdf } from '../../../assets/images/pdf.svg'
import { LoadingButton } from '@mui/lab'
import AttachmentFile from '../../common/AttachmentFileComponent/AttachmentFile'
import dashBoardServices from '../../../services/cspServices'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectUserReducer } from '../../../features/user'
import useFetch from '../../../hooks/useFetch'
import { useWindowSize } from 'usehooks-ts'
import { addAlert } from '../../../features/systemAlerts'
import { statusNames } from '../../../helper/constants'

interface IUpdateRequestModalModal {
    id: number
    subject: string
    open: boolean
    handleClose: () => void
    initialValues?: any
    customerId?: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

interface ReplyData {
    subject: string
    product: string
    RelatedTo: string
    priority: string
    description: string
    internal: string
    attachments: File[]
}
interface FormData {
    status: string
    product: string
    bugRelated: string
    bugNumber: string
    priority: string
    description: string
    attachments: File[]
    closureReason: ReplyData
    Resolution: ReplyData
    team: team
    message: string
    engineer: string
}
interface Engineer {
    id: number
    engineer: number
    is_leader: boolean
}

interface team {
    id: number
    name: string
    description: string
    engineers: Engineer[]
    engineers_count: number
}
interface IStyles {
    title: SxProps
    textField: SxProps
    attachments: SxProps
}
const status = [
    'OPENED',
    'ASSIGNED',
    'IN_PROGRESS',
    'WAITING_FOR_CUSTOMER',
    'WAITING_VENDOR_REPLY',
    'SOLVED',
    'CLOSED',
    'PERMANENTLY_CLOSED',
]
const UpdateRequestModal = ({
    handleClose,
    open,
    initialValues,
    customerId,
}: IUpdateRequestModalModal) => {
    const [filesIds, setFilesIds] = useState<any>([])
    const { user } = useAppSelector(selectUserReducer)
    const [priority, setPriority] = useState<any>([])
    const [currentStatus, setCurrentStatus] = useState<string | undefined>()
    const [teams, setTeams] = useState<any>()
    const [engineers, setEngineers] = useState<any>([])
    const [bugRelated, setBugRelated] = useState<string | undefined>()
    const [isPending, setIsPending] = useState(false)
    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    const [loading, setLoading] = useState(false)

    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])
    console.log('initialValues', initialValues)

    const [priorityData, setPriorityData] = useState<any>([])
    const [isPendingPriority, setIsPendingPriority] = useState(true)
    const [errorPriority, setErrorPriority] = useState<any>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dashBoardServices.getCustomerPriorities(
                    customerId
                )
                setPriorityData(response.data)
                setIsPendingPriority(false)
            } catch (error) {
                setErrorPriority(error)
                setIsPendingPriority(false)
            }
        }
        if (customerId) fetchData()
    }, [customerId])
    const dispatch = useAppDispatch()
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>()
    console.log('errors', errors)
    const productId = useWatch({
        control,
        name: 'product', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        // default value before the render
    })
    const { width, height } = useWindowSize()

    const onsubmit = (data: any) => {
        console.log('data', data)
        setIsPending(true)
        const requset = {
            status: data.status,
            assigned_to: data.engineer,
            priority: data.priority,
            bug_related: data.bugRelated,
            bug_number: data.bugNumber ?? '',
            resolution_reason:
                data.status == 'SOLVED'
                    ? {
                          internal: true,
                          action: 'RESOLUTION',
                          subject: initialValues.subject,
                          message: data.message,
                          attachments: filesIds.map((item: any) => {
                              return item.id
                          }),
                      }
                    : undefined,
            cancellation_reason: undefined,
            closure_reason:
                data.status == 'CLOSED'
                    ? {
                          internal: true,
                          action: 'CLOSURE',
                          subject: initialValues.subject,
                          message: data.message,
                          attachments: filesIds.map((item: any) => {
                              return item.id
                          }),
                      }
                    : undefined,
        }
        console.log('requset', requset)
        dashBoardServices
            .updateRequest(initialValues.id, requset)
            .then((res: any) => {
                console.log('resssssssssssssssssssssssss', res)
            })
            .catch((e) => {
                console.log('e', e)
            })
            .finally(() => {
                setIsPending(false)

                handleClose()
            })
    }
    console.log('firstName', productId)

    const onErrors = () => {}
    const handleDeleteRequest = (id: number) => {
        dashBoardServices
          .deleteIncidentAttachment(id)
          .then((res) => {
            if (res.status === 204) {
              const newIds = filesIds.filter((x: any) => x.id != id)
              setFilesIds(newIds)
            }
            console.log('res', res)
          })
          .catch((e) => {
            console.log('e', e)
          })
    }
    const handleFileUpload = (event: any) => {
        const Ids = [...filesIds]
        console.log('event', event)
        const file = event.target.files[0]
        console.log('file', file)
        const myformData = new FormData()
        myformData.append('file', file)
        setLoading(true)
        dashBoardServices
            .newFileAttachmentToReplay(myformData)
            .then((res: any) => {
                const FileObj: any = { id: res?.data?.id, name: file.name }
                console.log('res', res)
                Ids.push(FileObj)
                setFilesIds(Ids)
            })
            .catch((e) => {
                console.log('e', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        console.log('useEffect')
        if (initialValues && teams) {
            console.log('initialValues', initialValues)
            setValue('status', initialValues.status)
            setValue('bugRelated', initialValues.bug_related)
            setValue('bugNumber', initialValues.bug_number)
            setValue('priority', initialValues.priority.id)
            const currentTeam: any = teams.find(
                (team: any) => team.id == initialValues.assigned_to.team.team.id
            )
            console.log('currentTeam', currentTeam)
            setValue('team', currentTeam.id)
            setValue('engineer', initialValues.assigned_to.id)
        } else if (initialValues) {
            console.log('initialValues', initialValues)
            setValue('status', initialValues.status)
            setValue('bugRelated', initialValues.bug_related)
            setValue('bugNumber', initialValues.bug_number)
            setValue('priority', initialValues.priority.id)
            setValue('engineer', initialValues.assigned_to.id)
        }
    }, [initialValues, teams])

    useEffect(() => {
        if (
            priorityData &&
            !errorPriority &&
            !isPendingPriority &&
            priorityData.length > 0
        ) {
            console.log('priorityData', priorityData)
            setPriority(priorityData[0].priorities)
        }
    }, [priorityData])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log('type', type)
            if (name == 'status') {
                setCurrentStatus(value.status)
            }
            if (name == 'bugRelated') {
                setBugRelated(value.bugRelated)
            }
            // if (name == 'team' && teams) {
            //     const team = teams.find((item: any) => item.id == value)
            //     console.log('teamdidmount', team)
            //     setEngineers(team?.engineers)
            // }
            // if (name == 'team') {
            //     // setEngineers(value.team?.engineers)
            //     // if(initialValues)
            // }
            console.log(value, name, type)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])
    const handleModalClose = () => {
        // reset()
        handleClose()
    }
    useEffect(() => {
        if (user.user_type == 'ENGINEER')
            dashBoardServices
                .getLeaderTeams()
                .then(({ data }): any => {
                    console.log('data', data)
                    const meme: any = {}
                    const tempTeams: any = []
                    const tempEngnieers: any = []
                    data.map((item: any) => {
                        const {
                            team: {
                                team: { id, name },
                            },
                        } = item
                        tempEngnieers.push({
                            id: item.id,
                            engineer: item.id,
                            name: item.user.full_name,
                        })
                        const ID: string = id ?? ''
                        if (!meme[`${ID}`]) tempTeams.push({ id, name })
                        meme[`${ID}`] = true
                    })
                    setEngineers(tempEngnieers)
                    setTeams(tempTeams)
                })
                .catch((e) => {})
                .finally(() => {})

        return () => {}
    }, [])
    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            message: value?.message ?? 'missing inputs',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        maxHeight: chartDimensions,
                        overflow: 'scroll',
                        p: 3,
                    }}
                >
                    <CommonTypography
                        sx={{
                            marginTop: '10px',
                            marginLeft: '10px',
                            marginBottom: '48px',
                            color: '#000',
                            fontFamily: 'Inter',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                        variant={'h6'}
                    >
                        Request Update
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>status*</Typography>
                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        size="small"
                                        fullWidth
                                        {...field}
                                        sx={styles.textField}
                                    >
                                        {status.map(
                                            (item: string, i: number) => {
                                                return (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                        disabled={
                                                            item == 'OPENED' ||
                                                            item ==
                                                                'PERMANENTLY_CLOSED'
                                                        }
                                                    >
                                                        {
                                                            statusNames[
                                                                item.toLocaleLowerCase()
                                                            ]
                                                        }
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </TextField>
                                )}
                            />
                        </Stack>
                        {(currentStatus == 'SOLVED' ||
                            currentStatus == 'CLOSED') && (
                            <>
                                <Stack direction={'column'}>
                                    <Typography sx={styles.title}>
                                        {currentStatus == 'SOLVED'
                                            ? 'Resolution*'
                                            : 'Closure Reason*'}
                                    </Typography>
                                    <Controller
                                        name="message"
                                        control={control}
                                        rules={{
                                            required: 'message is required',
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                minRows={4}
                                                size="small"
                                                fullWidth
                                                {...field}
                                                sx={styles.textField}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Typography sx={styles.title}>
                                    Attachments
                                </Typography>
                                <Stack direction="row" sx={styles.attachments}>
                                    {filesIds.length > 0 &&
                                        filesIds.map((item: any) => {
                                            return (
                                                <AttachmentFile
                                                    key={item.id}
                                                    name={item.name}
                                                    IconFile={Pdf}
                                                    Id={item.id}
                                                    deleteFile={
                                                        handleDeleteRequest
                                                    }
                                                />
                                            )
                                        })}
                                    <Stack
                                        alignSelf={'flex-end'}
                                        sx={{
                                            maxWidth: '95px',
                                            marginLeft: 'auto',
                                        }}
                                    >
                                        <label htmlFor="upload-image">
                                            <Button
                                                component="span"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    borderRadius: '4px',
                                                    border: '1px solid #E7E5E5',
                                                    backgroundColor: '#FFF',
                                                    width: '95px',
                                                    height: '32px',
                                                    flexShrink: 0,
                                                }}
                                            >
                                                {!loading && (
                                                    <Add
                                                        style={{
                                                            marginRight: '6px',
                                                        }}
                                                    />
                                                )}
                                                {!loading && 'add'}
                                                {loading && (
                                                    <CircularProgress
                                                        size={25}
                                                    />
                                                )}
                                            </Button>
                                            <input
                                                id="upload-image"
                                                hidden
                                                accept="*"
                                                type="file"
                                                multiple
                                                onChange={handleFileUpload}
                                            />
                                        </label>
                                    </Stack>
                                </Stack>
                            </>
                        )}

                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>Priority*</Typography>
                            <Controller
                                name="priority"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        {...field}
                                        select
                                        sx={styles.textField}
                                    >
                                        {priority.map(
                                            (item: any, i: number) => {
                                                return (
                                                    <MenuItem
                                                        key={`priority${i}`}
                                                        value={item.id}
                                                    >
                                                        {item.priority_name}
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </TextField>
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>
                                Bug Related *
                            </Typography>
                            <Controller
                                name="bugRelated"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        size="small"
                                        fullWidth
                                        {...field}
                                        sx={styles.textField}
                                    >
                                        <MenuItem key={'no'} value={'NO'}>
                                            {'No'}
                                        </MenuItem>
                                        <MenuItem key={'yes'} value={'YES'}>
                                            {'Yes' + ' - ' + watch('bugNumber')}
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Stack>
                        {watch('bugRelated') == 'YES' && (
                            <Stack direction={'column'}>
                                <Typography sx={styles.title}>
                                    Bug number *
                                </Typography>
                                <Controller
                                    name="bugNumber"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            {...field}
                                            sx={styles.textField}
                                        ></TextField>
                                    )}
                                />
                            </Stack>
                        )}
                        {user.is_leader && (
                            <>
                                <Stack direction={'column'}>
                                    <Typography
                                        sx={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        Assignment
                                    </Typography>
                                    <Typography sx={styles.title}>
                                        Team
                                    </Typography>
                                    <Controller
                                        name="team"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                size="small"
                                                fullWidth
                                                {...field}
                                                sx={styles.textField}
                                            >
                                                {teams.map((item: any) => {
                                                    const { id, name } = item
                                                    return (
                                                        <MenuItem
                                                            key={id}
                                                            value={id}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        )}
                                    />
                                </Stack>
                                <Stack direction={'column'}>
                                    <Typography sx={styles.title}>
                                        Engineer
                                    </Typography>
                                    <Controller
                                        name="engineer"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                size="small"
                                                fullWidth
                                                {...field}
                                                sx={styles.textField}
                                            >
                                                {engineers.map(
                                                    ({
                                                        id,
                                                        engineer,
                                                        name,
                                                    }: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={id}
                                                                value={engineer}
                                                            >
                                                                {name ??
                                                                    engineer}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </TextField>
                                        )}
                                    />
                                </Stack>
                            </>
                        )}
                        <Stack
                            sx={{ marginTop: '48px' }}
                            direction={'row'}
                            spacing={3}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={handleModalClose}
                            >
                                {'Cancel'}
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant={'contained'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    boxShadow: 'none',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    borderRadius: '4px',
                                }}
                                // onClick={handleSubmit(onSubmit)}
                                loading={isPending}
                            >
                                Confirm
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles: IStyles = {
    textField: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',

        marginBottom: '22px',
    },
    title: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
        marginBottom: '8px',
    },
    attachments: {
        width: '838px',
        minHeight: '40px',
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        padding: '9px 12px 12px 9px',
    },
}
export default UpdateRequestModal
