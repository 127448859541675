import NewIncidentModal from '../../components/Modals/NewRequestModal/NewIncidentModal'
import RequestsTable from './RequestsTable'
import RequestModalFilter from '../../components/Modals/RequestModalFilter/RequestModalFilter'
import RequestsPageHeader from '../../components/RequestsView/RequestsPageHeader'
import LoadingSpinner from '../../components/shared/LoadingSpinner'
import RequestsEmptyState from '../../components/RequestsView/RequestsEmptyState'
import { useRequests } from '../../components/RequestsView/useRequests'

const RequestsPage = () => {
  const {
    newReqModalOpen,
    user,
    page,
    setPage,
    filterOpen,
    searchField,
    setSearchField,
    data,
    filters,
    setFilters,
    createdByFilter,
    setCreatedByFilter,
    filtersSelected,
    setFiltersSelected,
    mountRest,
    error,
    isPending,
    handleFetch,
    handleSearch,
    handleCloseNewReq,
    openNewRequestModal,
    handleCloseFilter,
    handleOpenFilter,
  } = useRequests()

  return (
    <>
      <RequestsPageHeader userType={user.user_type} />
      {isPending && <LoadingSpinner />}

      {!isPending && !error && data?.results?.length > 0 && (
        <RequestsTable
          handleOpenFilter={handleOpenFilter}
          openNewRequestModal={openNewRequestModal}
          requestsList={data}
          setSearchField={setSearchField}
          searchText={searchField}
          setSearchText={handleSearch}
          page={page}
          setPage={setPage}
        />
      )}
      {!isPending && !data?.results?.length && (
        <RequestsEmptyState
          userType={user.user_type}
          handleOpenNewRequest={openNewRequestModal}
        />
      )}
      {mountRest && (
        <>
          <NewIncidentModal
            handleClose={handleCloseNewReq}
            open={newReqModalOpen}
          />

          <RequestModalFilter
            filters={filters}
            setFilters={setFilters}
            createdByFilter={createdByFilter}
            setCreatedByFilter={setCreatedByFilter}
            handleClose={handleCloseFilter}
            open={filterOpen}
            handleFetch={handleFetch}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
          />
        </>
      )}
    </>
  )
}

export default RequestsPage
