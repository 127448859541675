import React, { useState } from 'react'
import AppLogo from '../AppLogo/AppLogo'
import { ReactComponent as NavLogo } from '../../assets/images/NavLogo.svg'
import { IconButton, Stack, Toolbar, Box } from '@mui/material'
import {
    EngineerLeaderSmallNavBarItems,
    EngineerSmallNavBarItems,
    UserSmallNavBarItems,
} from '../Navbar/consts/NavbarItems'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
const IconsNavBar = () => {
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState(10)
    const { user } = useAppSelector(userSelector)

    return (
        <Stack
            direction={'column'}
            sx={{
                height: '100%',
                minHeight: '100vh',
                width: '90px',
                background: '#33303B',
            }}
        >
            <Box position='fixed'>
            <Toolbar
                variant="regular"
                sx={{
                    marginBottom: '45px',
                }}
            >
                <Stack
                    direction={'row'}
                    sx={{ marginTop: '4rem' }}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                >
                    <NavLogo />
                </Stack>
            </Toolbar>

            {user.user_type == 'ENGINEER' &&
                user.is_leader &&
                EngineerLeaderSmallNavBarItems.map((item: any) => {
                    return (
                        <Stack
                            direction={'row'}
                            key={item.id}
                            justifyContent={'center'}
                            sx={{
                                marginBottom: '35px',
                            }}
                        >
                            <IconButton
                                sx={{
                                    backgroundColor:
                                        selectedId == item.id
                                            ? 'rgba(255, 255, 255, 0.1)'
                                            : '#33303B',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                                key={item.id}
                                onClick={() => {
                                    const route: string = item.route ?? ''
                                    const userId: string = user.user_id ?? ''
                                    setSelectedId(item.id)
                                    if (item.route.includes('/calender')) {
                                        navigate(`${route}/${userId}`)
                                    } else {
                                        navigate(item.route)
                                    }
                                }}
                            >
                                {item.icon}
                            </IconButton>
                        </Stack>
                    )
                })}
            {user.user_type == 'ENGINEER' &&
                !user.is_leader &&
                EngineerSmallNavBarItems.map((item: any) => {
                    return (
                        <Stack
                            direction={'row'}
                            key={item.id}
                            justifyContent={'center'}
                            sx={{
                                marginBottom: '35px',
                            }}
                        >
                            <IconButton
                                sx={{
                                    backgroundColor:
                                        selectedId == item.id
                                            ? 'rgba(255, 255, 255, 0.1)'
                                            : '#33303B',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                                key={item.id}
                                onClick={() => {
                                    setSelectedId(item.id)
                                    const route: string = item.route ?? ''
                                    const userId: string = user.user_id ?? ''
                                    if (item.route.includes('/calender')) {
                                        navigate(`${route}/${userId}`)
                                    } else {
                                        navigate(item.route)
                                    }
                                }}
                            >
                                {item.icon}
                            </IconButton>
                        </Stack>
                    )
                })}
            {user.user_type == 'CUSTOMER' &&
                UserSmallNavBarItems.map((item: any, i) => {
                    // if (user.is_project_manager && i == 1) return null
                    if (!user.is_admin&&!user.is_project_manager && i == 1) return null
                    return (
                        <Stack
                            direction={'row'}
                            key={item.id}
                            justifyContent={'center'}
                            sx={{
                                marginBottom: '35px',
                            }}
                        >
                            <IconButton
                                sx={{
                                    backgroundColor:
                                        selectedId == item.id
                                            ? 'rgba(255, 255, 255, 0.1)'
                                            : '#33303B',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                                key={item.id}
                                onClick={() => {
                                    setSelectedId(item.id)
                                    navigate(item.route)
                                }}
                            >
                                {item.icon}
                            </IconButton>
                        </Stack>
                    )
                })}

          </Box>      
        </Stack>
    )
}

export default IconsNavBar
